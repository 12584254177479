import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  UnorderedList,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { faArrowRight, faEyeSlash, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  Package_Contents,
  TaskItem,
  TaskItem_Contents_Skill_Section,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import {
  Course,
  CourseTier,
  HomeworkLength,
  StudentSettings,
} from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { SparxQuestion, useLayoutSteps } from '@sparx/question';
import { useTopicLookup, useTopicSummary } from 'api/content';
import { useExampleHomework } from 'api/planner';
import { useStudents, useWeekForDate } from 'api/school';
import { useUserType } from 'api/sessions';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import { StudentName } from 'components/Names';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import React, { useMemo, useState } from 'react';
import { PackageContentsViewer, SectionIconKey } from 'views/answerhistory/PackageContentsViewer';
import {
  getTaskItemSkill,
  TaskItemWrapper,
  usePackageContentsTaskItems,
} from 'views/answerhistory/TaskItemWrapper';
import { StudentSettingsSelector } from 'views/students/StudentBulkEdit';

import { useManageConsolidationTopicsModal } from '../ManageConsolidationTopics/Context';
import HWPreview from './icons/hw_preview.svg';
import { HomeworkItem } from './WeekButton';

interface AssignmentPreviewProps {
  assignment: Assignment;
}

export const AssignmentPreviewModal = ({ assignment }: AssignmentPreviewProps) => {
  const { sendEvent } = useClientEvent();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      sendEvent(
        { category: 'planner', action: 'open_assignment_preview' },
        {
          assignment: assignment.name,
        },
      );
    },
  });
  const { onOpen: openConsolidation, setMotionPreset } = useManageConsolidationTopicsModal();
  const week = useWeekForDate(assignment.startTimestamp, { suspense: false });

  // Don't show if homework is already generated
  if (assignment.generatedTimestamp) {
    return null;
  }

  const onManageConsolidationTopics = () => {
    // Have the consolidation modal slide in from the right
    setMotionPreset('slideInRight');
    onClose();
    openConsolidation();
    sendEvent(
      { category: 'preview_homework', action: 'manage_consolidation_topics' },
      { assignment: assignment.name },
    );
  };

  return (
    <>
      <Button
        ml={4}
        leftIcon={<FontAwesomeIcon icon={faFile} />}
        onClick={onOpen}
        variant="ghost"
        colorScheme="buttonTeal"
        size="sm"
      >
        Preview
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          padding={2}
          maxWidth="1200px"
          height="90vh"
          margin="5vh"
          display="flex"
          overflow="hidden"
        >
          <ModalHeader pb={1}>Preview{week && <> Week {week.index}</>} Homework</ModalHeader>
          <ModalBody>
            <AssignmentPreview
              assignment={assignment}
              manageConsolidationTopics={onManageConsolidationTopics}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

export const AssignmentPreview = ({
  assignment,
  manageConsolidationTopics,
}: AssignmentPreviewProps & { manageConsolidationTopics: () => void }) => {
  const { isSparxStaff } = useUserType();
  const [settings, setSettings] = useState<StudentSettings>({
    course: Course.COMBINED,
    name: '',
    level: '1',
    tier: CourseTier.LEVEL_FOUNDATION,
    homeworkLength: HomeworkLength.FULL,
    levelOverride: '1',
    levelAutomatic: '',
  });
  const [userID, setUserID] = useState<string | undefined>();
  const [showUserSelect, { toggle: toggleShowUserSelect }] = useBoolean();

  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [highlightedTopicName, setHighlightedTopicName] = useState<string | undefined>();

  const { data, isLoading, error } = useExampleHomework(assignment, settings, userID, {
    onSuccess: () => setSelectedItem(undefined),
  });
  const rpcError = error as RpcError;

  const { taskItem } = usePackageContentsTaskItems(data?.packageContents, selectedItem);

  return (
    <Flex flexDirection="column" height="100%">
      <Grid templateColumns="1.5fr repeat(3, 1fr)" gridGap={2} mb={4}>
        <GridItem display="flex" alignItems="center">
          <Text
            fontSize="sm"
            lineHeight="1.1em"
            color="gray.700"
            mr={4}
            onDoubleClick={() => {
              setUserID(undefined);
              toggleShowUserSelect();
            }}
          >
            See what this homework is likely to include on different courses, tiers and levels. The
            exact contents of the homework will differ per student.
          </Text>
        </GridItem>
        {isSparxStaff && showUserSelect ? (
          <StudentPicker
            userID={userID}
            setUserID={setUserID}
            groupID={assignment.groups?.[0]?.name.split('studentGroups/')[1] || ''}
          />
        ) : (
          <StudentSettingsSelector
            settings={settings}
            setSettings={s => setSettings({ ...settings, ...s })}
            forPreview={true}
          />
        )}
      </Grid>

      {isLoading ? (
        <Center flexDirection="column">
          <LargeLoading />
          <Text fontSize="lg" color="gray.500">
            Generating example homework...
          </Text>
        </Center>
      ) : data?.packageContents ? (
        <Box display="flex" flexGrow={1} flexShrink={0} position="relative">
          <Grid templateColumns="2fr 3fr" gridGap={2} position="absolute" inset="0 0 0 0">
            <GridItem overflowY="hidden" display="flex" flexDirection="column">
              <Button
                mb={4}
                onClick={() => setSelectedItem(undefined)}
                minHeight={12}
                justifyContent={'flex-start'}
                leftIcon={<Image src={HWPreview} />}
                rightIcon={
                  selectedItem === undefined ? <FontAwesomeIcon icon={faArrowRight} /> : undefined
                }
                backgroundColor={selectedItem === undefined ? 'blue.100' : 'gray.100'}
                borderRadius="base"
                fontWeight="normal"
                borderWidth={1}
                borderColor={selectedItem === undefined ? 'blue.800' : 'transparent'}
                _hover={selectedItem === undefined ? {} : undefined}
                _active={selectedItem === undefined ? {} : undefined}
              >
                <Text textAlign="left" flex={1}>
                  Homework overview
                </Text>
              </Button>
              <Box overflowY="auto">
                <PackageContentsViewer
                  contents={data.packageContents}
                  selectedItemName={selectedItem}
                  onClickItem={item => {
                    setSelectedItem(item === selectedItem ? undefined : item);
                    setHighlightedTopicName(undefined);
                  }}
                  showSectionType={true}
                  highlightedTopicName={highlightedTopicName}
                  noTopMarginOnFirstSibject={true}
                />
              </Box>
            </GridItem>
            <TaskItemWrapper
              contents={data.packageContents}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              noSelectedItemTitle="Homework overview"
            >
              <Box
                flex={1}
                mx="3px"
                mb="3px"
                mt={selectedItem ? 0 : '3px'}
                borderRadius="4px"
                overflowY="auto"
                bg="white"
                px={5}
                py={4}
              >
                {taskItem ? (
                  <QuestionPreviewPanel taskItem={taskItem} />
                ) : (
                  <PackageOverviewPanel
                    contents={data?.packageContents}
                    setHighlightedTopicName={setHighlightedTopicName}
                    highlightedTopicName={highlightedTopicName}
                    manageConsolidationTopics={manageConsolidationTopics}
                  />
                )}
              </Box>
            </TaskItemWrapper>
          </Grid>
        </Box>
      ) : (
        <Alert status="warning" borderRadius="md">
          <AlertIcon />
          {rpcError.code === 'FAILED_PRECONDITION' ? (
            <Text>
              You have not selected enough content to generate a homework. You should ensure that:
              <UnorderedList>
                <ListItem>There are topics in the homework</ListItem>
                <ListItem>Topics in the homework don't say "(not yet available)"</ListItem>
                <ListItem>Or topics have been set previously for this class</ListItem>
              </UnorderedList>
            </Text>
          ) : (
            <Text>
              There was an internal error generating a preview homework. Please try again later.
            </Text>
          )}
        </Alert>
      )}
    </Flex>
  );
};

interface QuestionPreviewPanelProps {
  taskItem: TaskItem;
}

const QuestionPreviewPanel = ({ taskItem }: QuestionPreviewPanelProps) => {
  const { sendEvent } = useClientEvent();
  const taskItemSkill = getTaskItemSkill(taskItem);
  const { data: topic, isLoading } = useTopicSummary(taskItemSkill?.topicName || '', {
    suspense: false,
    enabled: taskItemSkill?.topicName !== undefined,
  });
  const skill = topic?.topicSummary?.keyQuestions.find(
    q => q.skillName === `skills/${taskItemSkill?.skillId}`,
  );
  const question = skill?.questionSummary;
  const steps = useLayoutSteps(question?.layoutJson);

  return (
    <>
      {isLoading ? (
        <LargeLoading />
      ) : steps.length > 0 && steps[0] ? (
        <SparxQuestion
          layout={steps[0].layout}
          input={steps[0].input}
          setInput={() => {
            /* empty */
          }}
          shuffleSeed={question?.questionName || ''}
          readOnly={true}
          getUploadedAsset={uploadedAssetProvider}
          getAssetUrl={getAssetUrl}
          sendAnalyticEvent={(action, labels) =>
            sendEvent({ category: 'question', action }, labels)
          }
          inlineSlotsAndNumberFields={true}
        />
      ) : (
        <>Error loading question</>
      )}
    </>
  );
};

interface OverviewPanelProps {
  contents?: Package_Contents;
  highlightedTopicName?: string;
  setHighlightedTopicName: (topicName: string | undefined) => void;
  manageConsolidationTopics: () => void;
}

const PackageOverviewPanel = ({
  contents,
  highlightedTopicName,
  setHighlightedTopicName,
  manageConsolidationTopics,
}: OverviewPanelProps) => {
  const { infocus, consolidation } = useMemo(() => {
    const topicsBySection: Record<TaskItem_Contents_Skill_Section, Dictionary<string, number>> = {
      [TaskItem_Contents_Skill_Section.SECTION_UNSPECIFIED]: {},
      [TaskItem_Contents_Skill_Section.INFOCUS]: {},
      [TaskItem_Contents_Skill_Section.CONSOLIDATION]: {},
    };
    for (const task of contents?.tasks || []) {
      for (const taskItem of task.contents?.taskItems || []) {
        if (taskItem.contents?.contents.oneofKind === 'skill') {
          const { topicName, section } = taskItem.contents.contents.skill;
          topicsBySection[section][topicName] = (topicsBySection[section][topicName] || 0) + 1;
        }
      }
    }
    return {
      infocus: sortSkills(topicsBySection[TaskItem_Contents_Skill_Section.INFOCUS]),
      consolidation: sortSkills(topicsBySection[TaskItem_Contents_Skill_Section.CONSOLIDATION]),
    };
  }, [contents]);

  return (
    <>
      <TopicList
        section={TaskItem_Contents_Skill_Section.INFOCUS}
        topics={infocus}
        setHighlightedTopicName={setHighlightedTopicName}
        highlightedTopicName={highlightedTopicName}
      />
      <Box h={6} />
      <TopicList
        section={TaskItem_Contents_Skill_Section.CONSOLIDATION}
        topics={consolidation}
        setHighlightedTopicName={setHighlightedTopicName}
        highlightedTopicName={highlightedTopicName}
        manageConsolidationTopics={manageConsolidationTopics}
      />
    </>
  );
};

const sortSkills = (skills: Dictionary<string, number>) =>
  Object.entries(skills)
    .map(([topicName, skillCount = 0]) => ({ topicName, skillCount }))
    .sort(
      (a, b) => (b.skillCount || 0) - (a.skillCount || 0) || a.topicName.localeCompare(b.topicName),
    );

interface TopicListProps {
  section: TaskItem_Contents_Skill_Section;
  highlightedTopicName?: string;
  setHighlightedTopicName: (topicName: string | undefined) => void;
  topics: { topicName: string; skillCount: number }[];
  manageConsolidationTopics?: () => void;
}

const sectionName: Record<TaskItem_Contents_Skill_Section, string> = {
  [TaskItem_Contents_Skill_Section.SECTION_UNSPECIFIED]: 'Unknown',
  [TaskItem_Contents_Skill_Section.INFOCUS]: 'Set this week',
  [TaskItem_Contents_Skill_Section.CONSOLIDATION]: 'Consolidation topics',
};

const TopicList = ({
  section,
  highlightedTopicName,
  setHighlightedTopicName,
  topics,
  manageConsolidationTopics,
}: TopicListProps) => {
  const { data: topicLookup } = useTopicLookup({ suspense: false });

  return (
    <>
      <HStack spacing={2} mb={2}>
        <SectionIconKey section={section} />
        <Text fontWeight="bold">{sectionName[section]}</Text>
        {section === TaskItem_Contents_Skill_Section.CONSOLIDATION && manageConsolidationTopics && (
          <Button colorScheme="gray" onClick={manageConsolidationTopics}>
            Manage Consolidation topics
          </Button>
        )}
      </HStack>
      {topics.length === 0 && (
        <Text color="gray.500">
          No{' '}
          {section === TaskItem_Contents_Skill_Section.INFOCUS
            ? 'new topics'
            : sectionName[section].toLowerCase()}{' '}
          will be set in this homework.
        </Text>
      )}
      {topics.length > 0 && section === TaskItem_Contents_Skill_Section.CONSOLIDATION && (
        <ConsolidationInfo />
      )}
      {topics.map(({ topicName, skillCount }) => {
        const highlighted = highlightedTopicName === topicName;
        const toggle = () => setHighlightedTopicName(highlighted ? undefined : topicName);

        return (
          <HomeworkItem key={topicName}>
            {topicLookup?.[topicName]?.topic.displayName}
            <Button
              variant="link"
              onClick={toggle}
              fontWeight="normal"
              colorScheme={'blue'}
              ml={2}
              rightIcon={highlighted ? <FontAwesomeIcon icon={faEyeSlash} /> : undefined}
            >
              ({skillCount} task item
              {skillCount === 1 ? '' : 's'})
            </Button>
          </HomeworkItem>
        );
      })}
    </>
  );
};

const ConsolidationInfo = () => (
  <Text mb={2} color="gray.500" fontSize="sm" px={3} py={2} borderRadius="md" bg="gray.100">
    These topics are based on previous homework that was set for this class.
  </Text>
);

const StudentPicker = ({
  userID,
  setUserID,
  groupID,
}: {
  userID?: string;
  setUserID: (id: string | undefined) => void;
  groupID: string;
}) => {
  const { data: students, isLoading } = useStudents({ suspense: false });

  const groupStudents = (students || [])
    .filter(student => {
      return student.studentGroupIds.includes(groupID);
    })
    .sort(
      (a, b) =>
        a.givenName.localeCompare(b.givenName) ||
        a.familyName.localeCompare(b.familyName) ||
        a.studentId.localeCompare(b.studentId) ||
        0,
    );

  const notInGroup =
    userID !== undefined &&
    groupStudents.find(student => student.studentId === userID) === undefined;

  return (
    <>
      <Text textAlign="right">Enter a user ID to preview for a specific user:</Text>
      {isLoading ? (
        <LargeLoading />
      ) : (
        <Select value={userID || 'none'} onChange={e => setUserID(e.target.value)}>
          <option value={'none'} disabled={!!userID}>
            Select a student
          </option>
          {groupStudents.map(student => (
            <option key={student.studentId} value={student.studentId}>
              <StudentName student={student} />
            </option>
          ))}
          {notInGroup && (
            <option value={userID} disabled={true}>
              Unknown user ID
            </option>
          )}
        </Select>
      )}
      <Input
        ml={2}
        value={userID}
        onChange={e => setUserID(e.target.value !== '' ? e.target.value : undefined)}
      />
    </>
  );
};
