import { QueryKey, UseQueryResult } from '@tanstack/react-query';

import { CustomOpts, Procedure } from './procedure';

// The `Procedure` type is deliberately flexible but this makes it difficult to use as an interface.
// This is a narrower version we can use for the below helper.
interface ProcedureNarrowed<Return, Client, Params, Key extends QueryKey>
  extends Procedure<Return, Client, Params, Key> {
  useQuery: <T = Return>(opts: CustomOpts<Return, T>) => UseQueryResult<T, Error>;
  useSuspenseQuery: <T = Return>(opts: CustomOpts<Return, T>) => UseQueryResult<T, Error>;
}

/**
 * A utility function for creating a hook which artificially allows the caller to pass a `suspense` parameter
 * instead of using the individual hooks which will have to be used in React Query v5. Not recommended for new code.
 */
export const makeOptionalSuspenseQuery =
  <Client, Params, Return, Key extends QueryKey>(
    procedure: ProcedureNarrowed<Return, Client, Params, Key>,
  ) =>
  <TData = Return>(opts: CustomOpts<Return, TData> & { suspense: boolean }) => {
    const queryResult = procedure.useQuery({ ...opts, enabled: !opts.suspense });
    const suspenseQueryResult = procedure.useSuspenseQuery({ ...opts, enabled: opts.suspense });
    return opts.suspense ? suspenseQueryResult : queryResult;
  };
