// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/v2/skill.proto" (package "sparx.content.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { TargetedSupportConfig } from '../../hints/v1/hints';
import { TargetedSupport } from '../../hints/v1/hints';
import { HintType } from '../../hints/v1/hints';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * A Skill is the minimum item of learning in the content.
 *
 * Skills contain 1 or more questions of equivalent style and difficulty
 * along with a help video to provide a hint.
 *
 * @generated from protobuf message sparx.content.v2.Skill
 */
export interface Skill {
  /**
   * The resource name of the skill.
   * Skill names have the form `skills/{skill_id}`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  // Fields reserved for future use - likely to be used in some form.
  // bool deleted = 2;
  // Timestamp create_time = 3;

  /**
   * Timestamp that this skill was exported.
   *
   * @generated from protobuf field: google.protobuf.Timestamp exported_timestamp = 4;
   */
  exportedTimestamp?: Timestamp;
  /**
   * The ID for the video
   * This may later be replaced with a video resource name.
   *
   * @generated from protobuf field: string video_id = 106;
   */
  videoId: string; // Reserved for future video resource name use.
  // string video_name = 6;
  /**
   * Flags affecting usage of the Skill. See SkillFlag for details.
   * Any flag that appears at least once is considered to be `set`.
   *
   * @generated from protobuf field: repeated sparx.content.v2.SkillFlag flags = 7;
   */
  flags: SkillFlag[];
  /**
   * Additional equipment required to complete this skill. Any repeated
   * values are ignored.
   *
   * @generated from protobuf field: repeated sparx.content.v2.AdditionalEquipment additional_equipment = 8;
   */
  additionalEquipment: AdditionalEquipment[];
  /**
   * Optional support material on the skill.
   *
   * @generated from protobuf field: sparx.content.v2.SkillSupportMaterial support_material = 9;
   */
  supportMaterial?: SkillSupportMaterial;
  /**
   * Tags are any strings associated with the Skill.  It's up to the consumer to
   * give them meaning.
   *
   * @generated from protobuf field: repeated string tags = 10;
   */
  tags: string[];
  /**
   * Optional hints for the skill.
   *
   * @generated from protobuf field: repeated sparx.hints.v1.HintType hints = 11;
   */
  hints: HintType[];
  /**
   * Optional targeted support for the skill.
   *
   * @generated from protobuf field: repeated sparx.hints.v1.TargetedSupport targeted_support = 12;
   */
  targetedSupport: TargetedSupport[];
}
/**
 * SkillSupportMaterial is some optional additional information on a skill.
 *
 * @generated from protobuf message sparx.content.v2.SkillSupportMaterial
 */
export interface SkillSupportMaterial {
  /**
   * Text support material in markdown format.
   *
   * @generated from protobuf field: string text = 1;
   */
  text: string;
}
/**
 * A Question can be delivered to a learner to answer.
 *
 * @generated from protobuf message sparx.content.v2.Question
 */
export interface Question {
  /**
   * The resource name of the question.
   * Question names have the form `skills/{skill_id}/questions/{question_id}`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  // Fields reserved for future use - likely to be used in some form.
  // bool deleted = 2;
  // Timestamp create_time = 3;

  /**
   * Timestamp that this question was exported.
   *
   * @generated from protobuf field: google.protobuf.Timestamp exported_timestamp = 4;
   */
  exportedTimestamp?: Timestamp;
  /**
   * The definition of the question in XML.
   * This field is deprecated since moving question creation to Curman. Use fields in QuestionContent instead.
   * Questions created in QM will have this field set, but new questions created in Curman will not.
   *
   * @deprecated
   * @generated from protobuf field: string xml_spec = 6 [deprecated = true];
   */
  xmlSpec: string;
  /**
   * Flags affecting usage of the Question. See QuestionFlag for details.
   * Any flag that appears at least once is considered to be `set`.
   *
   * @generated from protobuf field: repeated sparx.content.v2.QuestionFlag flags = 7;
   */
  flags: QuestionFlag[];
  /**
   * The resolved content of the question that is calculated from the XML.
   *
   * @generated from protobuf field: sparx.content.v2.QuestionContent content = 106;
   */
  content?: QuestionContent;
  /**
   * Targeted support config for this question.
   *
   * @generated from protobuf field: repeated sparx.hints.v1.TargetedSupportConfig targeted_support_config = 8;
   */
  targetedSupportConfig: TargetedSupportConfig[];
}
/**
 * QuestionContent contains information calculated from a Question's xml_spec.
 *
 * @generated from protobuf message sparx.content.v2.QuestionContent
 */
export interface QuestionContent {
  /**
   * The layout for the question to be presented in sparxweb in JSON.
   *
   * @generated from protobuf field: string layout_json = 1;
   */
  layoutJson: string;
  /**
   * The answer evaluator encoded in protobuf messages.
   *
   * @generated from protobuf field: bytes answer_evaluator_bytes = 2;
   */
  answerEvaluatorBytes: Uint8Array;
  /**
   * The possible answers for the question, encoded in protobuf messages.
   *
   * @generated from protobuf field: bytes answer_options_bytes = 3;
   */
  answerOptionsBytes: Uint8Array;
  /**
   * The template to present answer data for this question, encoded in XML.
   *
   * @generated from protobuf field: string answer_template_xml = 4;
   */
  answerTemplateXml: string;
  /**
   * A displayable correct answer for this question, encoded in XML.
   * Deprecated: correct_answer_xml is deprecated in favour of computing the correct answer from other fields on this object, see GetTemplatedCorrectAnswerForActivity.
   *
   * @deprecated
   * @generated from protobuf field: string correct_answer_xml = 5 [deprecated = true];
   */
  correctAnswerXml: string;
}
// Begin service messages descriptions.

/**
 * Request message for Skills.ListSkills.
 *
 * @generated from protobuf message sparx.content.v2.ListSkillsRequest
 */
export interface ListSkillsRequest {
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListSkillsResponse.next_page_token] from
   * a previous `ListSkills` request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
}
/**
 * Response message for Skills.ListSkills.
 *
 * @generated from protobuf message sparx.content.v2.ListSkillsResponse
 */
export interface ListSkillsResponse {
  /**
   * The list of Skills
   *
   * @generated from protobuf field: repeated sparx.content.v2.Skill skills = 1;
   */
  skills: Skill[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListSkillsRequest.page_token` in a
   * subsequent call to ListSkills to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Skills.BatchGetSkills.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetSkillsRequest
 */
export interface BatchGetSkillsRequest {
  /**
   * Required. The names of the Skills to retrieve. The server may refuse to
   * respond if the list is too long. Skills that do not exist will not cause an
   * error.
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * Response message for Skills.BatchGetSkills.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetSkillsResponse
 */
export interface BatchGetSkillsResponse {
  /**
   * The list of Skills. May be different order and length to request.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Skill skills = 1;
   */
  skills: Skill[];
}
/**
 * Request message for Skills.GetSkill.
 *
 * @generated from protobuf message sparx.content.v2.GetSkillRequest
 */
export interface GetSkillRequest {
  /**
   * Required. The name of the Skill to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Request message for Skills.ListQuestions.
 *
 * @generated from protobuf message sparx.content.v2.ListQuestionsRequest
 */
export interface ListQuestionsRequest {
  /**
   * Required. The parent (Skill) to list the Questions for. Specified in the
   * format `skills/*`. Skill "-" matches all skills. Wildcard usage
   * will cause the result to be paged.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListQuestionsResponse.next_page_token] from
   * a previous `ListQuestions` request.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * Response message for Skills.ListQuestions.
 *
 * @generated from protobuf message sparx.content.v2.ListQuestionsResponse
 */
export interface ListQuestionsResponse {
  /**
   * The list of Questions.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Question questions = 1;
   */
  questions: Question[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListQuestionsRequest.page_token` in a
   * subsequent call to ListQuestions to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for Skills.BatchGetQuestions.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetQuestionsRequest
 */
export interface BatchGetQuestionsRequest {
  /**
   * Required. The names of the Questions to retrieve. The server may refuse to
   * respond if the list is too long. Questions that do not exist will not cause
   * an error.
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * Response message for Skills.BatchGetQuestions.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetQuestionsResponse
 */
export interface BatchGetQuestionsResponse {
  /**
   * The list of Questions. May be different order and length to request.
   *
   * @generated from protobuf field: repeated sparx.content.v2.Question questions = 1;
   */
  questions: Question[];
}
/**
 * Request message for Skills.GetQuestion.
 *
 * @generated from protobuf message sparx.content.v2.GetQuestionRequest
 */
export interface GetQuestionRequest {
  /**
   * Required. The name of the Question to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * SkillFlags control various aspects of skill usage and delivery.
 *
 * @generated from protobuf enum sparx.content.v2.SkillFlag
 */
export enum SkillFlag {
  /**
   * Default enum value mapping to no flag
   *
   * @generated from protobuf enum value: SKILLFLAG_UNSPECIFIED = 0;
   */
  SKILLFLAG_UNSPECIFIED = 0,
  /**
   * Whether this skill should not be used in task generation and similar.
   *
   * @generated from protobuf enum value: SKILLFLAG_SCRIPT_EXCLUDED = 1;
   */
  SKILLFLAG_SCRIPT_EXCLUDED = 1,
  /**
   * Whether the answers in this skill require a teacher to mark them.
   *
   * @generated from protobuf enum value: SKILLFLAG_MARKING_REQUIRED = 2;
   */
  SKILLFLAG_MARKING_REQUIRED = 2,
  /**
   * Whether this skill should be used in bookwork checks.
   *
   * @generated from protobuf enum value: SKILLFLAG_BOOKWORK_CHECKABLE = 3;
   */
  SKILLFLAG_BOOKWORK_CHECKABLE = 3,
  /**
   * Whether the answers to this skill should be ignored for modelling or
   * timing calculations. For example `Write down the date and time` or `How
   * confident are you at Algebra?`, or `Yes, I have done this`
   *
   * @generated from protobuf enum value: SKILLFLAG_TRIVIAL = 4;
   */
  SKILLFLAG_TRIVIAL = 4,
  /**
   * Whether this skill should be delivered in `repeated question mode`
   * so students reattempting this skill will receive the same question.
   * This flag has been deprecated and skills should no longer be flagged with it.
   *
   * @deprecated
   * @generated from protobuf enum value: SKILLFLAG_REPEAT_QUESTION = 5 [deprecated = true];
   */
  SKILLFLAG_REPEAT_QUESTION = 5,
  /**
   * Whether this skill is for higher courses rather than foundation.
   *
   * @generated from protobuf enum value: SKILLFLAG_HIGHER = 6;
   */
  SKILLFLAG_HIGHER = 6,
  /**
   * Whether this skill is for separate science rather than combined science.
   *
   * @generated from protobuf enum value: SKILLFLAG_SEPARATE_SCIENCE = 7;
   */
  SKILLFLAG_SEPARATE_SCIENCE = 7,
  /**
   * Whether to mark this question at a part by part basis (rather than gap or overall)
   * This currently only affects for Maths skills
   *
   * @generated from protobuf enum value: SKILLFLAG_MULTI_PART_MARKING = 8;
   */
  SKILLFLAG_MULTI_PART_MARKING = 8,
  /**
   * Whether we should accept equivalent answers as the expected answers e.g. for equivalent
   * fractions or ratios
   *
   * @generated from protobuf enum value: SKILLFLAG_ALLOW_EQUIVALENT_ANSWERS = 9;
   */
  SKILLFLAG_ALLOW_EQUIVALENT_ANSWERS = 9,
}
/**
 * AdditionalEquipment specified when learners require non-standard equipment
 * to complete skills.
 * Equipment is separate from other flags to allow consumers to handle
 * unrecognised entities better.
 *
 * @generated from protobuf enum sparx.content.v2.AdditionalEquipment
 */
export enum AdditionalEquipment {
  /**
   * Default enum value mapping to nothing
   *
   * @generated from protobuf enum value: ADDITIONAL_EQUIPMENT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * This skill requires a protractor to complete.
   *
   * @generated from protobuf enum value: ADDITIONAL_EQUIPMENT_PROTRACTOR = 1;
   */
  PROTRACTOR = 1,
  /**
   * This skill requires a pair of compasses to complete.
   *
   * @generated from protobuf enum value: ADDITIONAL_EQUIPMENT_COMPASS = 2;
   */
  COMPASS = 2,
  /**
   * This skill requires a ruler to complete.
   *
   * @generated from protobuf enum value: ADDITIONAL_EQUIPMENT_RULER = 3;
   */
  RULER = 3,
  /**
   * This skill requires squared paper to complete.
   *
   * @generated from protobuf enum value: ADDITIONAL_EQUIPMENT_SQUARED_PAPER = 4;
   */
  SQUARED_PAPER = 4,
  /**
   * This skill requires graph paper to complete.
   *
   * @generated from protobuf enum value: ADDITIONAL_EQUIPMENT_GRAPH_PAPER = 5;
   */
  GRAPH_PAPER = 5,
}
/**
 * QuestionFlags control various aspects of question usage and delivery.
 *
 * @generated from protobuf enum sparx.content.v2.QuestionFlag
 */
export enum QuestionFlag {
  /**
   * Default enum value mapping to no flag
   *
   * @generated from protobuf enum value: QUESTIONFLAG_UNSPECIFIED = 0;
   */
  QUESTIONFLAG_UNSPECIFIED = 0,
  /**
   * This question is a support question.
   *
   * @generated from protobuf enum value: QUESTIONFLAG_SUPPORT = 1;
   */
  QUESTIONFLAG_SUPPORT = 1,
  /**
   * This question has multiple steps and should be delivered stepped.
   * Stepped means that previous steps should not be visible as context
   * when new steps are issued.
   *
   * @generated from protobuf enum value: QUESTIONFLAG_MULTISTEP_STEPPED = 2;
   */
  QUESTIONFLAG_MULTISTEP_STEPPED = 2,
  /**
   * This question has multiple steps and should be delivered continuous.
   * Continuous means that previous steps should be visible as context
   * when new steps are issued.
   *
   * @generated from protobuf enum value: QUESTIONFLAG_MULTISTEP_CONTINUOUS = 3;
   */
  QUESTIONFLAG_MULTISTEP_CONTINUOUS = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class Skill$Type extends MessageType<Skill> {
  constructor() {
    super('sparx.content.v2.Skill', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'exported_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 106, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.content.v2.SkillFlag', SkillFlag],
      },
      {
        no: 8,
        name: 'additional_equipment',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.content.v2.AdditionalEquipment',
          AdditionalEquipment,
          'ADDITIONAL_EQUIPMENT_',
        ],
      },
      {
        no: 9,
        name: 'support_material',
        kind: 'message',
        T: () => SkillSupportMaterial,
      },
      {
        no: 10,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: 'hints',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.hints.v1.HintType', HintType],
      },
      {
        no: 12,
        name: 'targeted_support',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TargetedSupport,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.Skill
 */
export const Skill = new Skill$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkillSupportMaterial$Type extends MessageType<SkillSupportMaterial> {
  constructor() {
    super('sparx.content.v2.SkillSupportMaterial', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.SkillSupportMaterial
 */
export const SkillSupportMaterial = new SkillSupportMaterial$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Question$Type extends MessageType<Question> {
  constructor() {
    super('sparx.content.v2.Question', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'exported_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 6, name: 'xml_spec', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.content.v2.QuestionFlag', QuestionFlag],
      },
      { no: 106, name: 'content', kind: 'message', T: () => QuestionContent },
      {
        no: 8,
        name: 'targeted_support_config',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TargetedSupportConfig,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.Question
 */
export const Question = new Question$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionContent$Type extends MessageType<QuestionContent> {
  constructor() {
    super('sparx.content.v2.QuestionContent', [
      {
        no: 1,
        name: 'layout_json',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'answer_evaluator_bytes',
        kind: 'scalar',
        T: 12 /*ScalarType.BYTES*/,
      },
      {
        no: 3,
        name: 'answer_options_bytes',
        kind: 'scalar',
        T: 12 /*ScalarType.BYTES*/,
      },
      {
        no: 4,
        name: 'answer_template_xml',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'correct_answer_xml',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.QuestionContent
 */
export const QuestionContent = new QuestionContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSkillsRequest$Type extends MessageType<ListSkillsRequest> {
  constructor() {
    super('sparx.content.v2.ListSkillsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListSkillsRequest
 */
export const ListSkillsRequest = new ListSkillsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSkillsResponse$Type extends MessageType<ListSkillsResponse> {
  constructor() {
    super('sparx.content.v2.ListSkillsResponse', [
      {
        no: 1,
        name: 'skills',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Skill,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListSkillsResponse
 */
export const ListSkillsResponse = new ListSkillsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetSkillsRequest$Type extends MessageType<BatchGetSkillsRequest> {
  constructor() {
    super('sparx.content.v2.BatchGetSkillsRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetSkillsRequest
 */
export const BatchGetSkillsRequest = new BatchGetSkillsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetSkillsResponse$Type extends MessageType<BatchGetSkillsResponse> {
  constructor() {
    super('sparx.content.v2.BatchGetSkillsResponse', [
      {
        no: 1,
        name: 'skills',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Skill,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetSkillsResponse
 */
export const BatchGetSkillsResponse = new BatchGetSkillsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSkillRequest$Type extends MessageType<GetSkillRequest> {
  constructor() {
    super('sparx.content.v2.GetSkillRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetSkillRequest
 */
export const GetSkillRequest = new GetSkillRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListQuestionsRequest$Type extends MessageType<ListQuestionsRequest> {
  constructor() {
    super('sparx.content.v2.ListQuestionsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListQuestionsRequest
 */
export const ListQuestionsRequest = new ListQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListQuestionsResponse$Type extends MessageType<ListQuestionsResponse> {
  constructor() {
    super('sparx.content.v2.ListQuestionsResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListQuestionsResponse
 */
export const ListQuestionsResponse = new ListQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetQuestionsRequest$Type extends MessageType<BatchGetQuestionsRequest> {
  constructor() {
    super('sparx.content.v2.BatchGetQuestionsRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetQuestionsRequest
 */
export const BatchGetQuestionsRequest = new BatchGetQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetQuestionsResponse$Type extends MessageType<BatchGetQuestionsResponse> {
  constructor() {
    super('sparx.content.v2.BatchGetQuestionsResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetQuestionsResponse
 */
export const BatchGetQuestionsResponse = new BatchGetQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionRequest$Type extends MessageType<GetQuestionRequest> {
  constructor() {
    super('sparx.content.v2.GetQuestionRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetQuestionRequest
 */
export const GetQuestionRequest = new GetQuestionRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.content.v2.Skills
 */
export const Skills = new ServiceType('sparx.content.v2.Skills', [
  {
    name: 'ListSkills',
    options: { 'google.api.http': { get: '/v2/skills' } },
    I: ListSkillsRequest,
    O: ListSkillsResponse,
  },
  {
    name: 'BatchGetSkills',
    options: { 'google.api.http': { get: '/v2/skills:batchGet' } },
    I: BatchGetSkillsRequest,
    O: BatchGetSkillsResponse,
  },
  {
    name: 'GetSkill',
    options: { 'google.api.http': { get: '/v2/{name=skills/*}' } },
    I: GetSkillRequest,
    O: Skill,
  },
  {
    name: 'ListQuestions',
    options: { 'google.api.http': { get: '/v2/{parent=skills/*}/questions' } },
    I: ListQuestionsRequest,
    O: ListQuestionsResponse,
  },
  {
    name: 'BatchGetQuestions',
    options: { 'google.api.http': { get: '/v2/skills/-/questions:batchGet' } },
    I: BatchGetQuestionsRequest,
    O: BatchGetQuestionsResponse,
  },
  {
    name: 'GetQuestion',
    options: { 'google.api.http': { get: '/v2/{name=skills/*/questions/*}' } },
    I: GetQuestionRequest,
    O: Question,
  },
]);
