import { Box, Flex, Link, Text, VStack } from '@chakra-ui/react';
import { SubstrandSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { WorkUnit } from '@sparx/api/apis/sparx/planning/v1/sol';
import { TopicWithStrand } from 'api/content';
import { TopicPickerWithFilter } from 'components/TopicPicker';
import { useTopicSearchContext } from 'components/topicsearch/Context';
import { FilterFields, TopicSearchInput } from 'components/topicsearch/TopicSearch';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTopicSearch } from 'views/planner/components/topicSearch';
import { Draggable } from 'views/sol/components/Draggable';
import { TopicData } from 'views/sol/components/TopicData';

interface FindTopicsProps {
  topicLookup: Dictionary<string, TopicWithStrand>;
  items: WorkUnit[];
  setItems: (items: WorkUnit[]) => void;
}

export const FindTopics = ({ topicLookup, items, setItems }: FindTopicsProps) => {
  const { filter, setFilter } = useTopicSearchContext();
  const [substrand, setSubstrand] = useState<SubstrandSummary | undefined>(undefined);

  const selectSubstrand = useCallback(
    (substrand: SubstrandSummary | undefined) => {
      if (substrand?.substrand) {
        setItems(
          substrand.substrand.topicNames.map(topicName => ({
            workUnitId: uuid(),
            payload: {
              oneofKind: 'topic',
              topic: topicName,
            },
          })),
        );
      } else {
        setItems([]);
      }
      setSubstrand(substrand);
    },
    [setItems, setSubstrand],
  );

  const hasSearch = filter.search !== '';
  const topics: TopicWithStrand[] = useMemo(
    () => Object.values(topicLookup) as TopicWithStrand[],
    [topicLookup],
  );
  const filteredTopics = useTopicSearch(topics, filter, {
    enabled: hasSearch,
  });

  // Handle updating the Items used for drag and drop, we keep track of the
  // filter so we'll only update them when it changes
  const lastFilter = useRef<FilterFields>({ search: '', curriculum: '' });
  useEffect(() => {
    if (
      filter.search !== lastFilter.current.search ||
      filter.curriculum !== lastFilter.current.curriculum
    ) {
      if (filter.search === '') {
        selectSubstrand(substrand);
      } else {
        setItems(
          filteredTopics.map(t => ({
            workUnitId: uuid(),
            payload: {
              oneofKind: 'topic',
              topic: t.topic.name,
            },
          })),
        );
      }
      lastFilter.current = filter;
    }
  }, [filteredTopics, filter, setItems, selectSubstrand, substrand]);

  return (
    <>
      <Box bg="white" px={4} pt={4}>
        <TopicSearchInput width="100%" />
      </Box>

      {hasSearch && (
        <Box mt={2} p={4} overflow="auto">
          <VStack spacing={1} alignItems="stretch">
            {items.slice(0, 15).map(item => (
              <Draggable key={item.workUnitId} id={item.workUnitId}>
                {props =>
                  item.payload.oneofKind === 'topic' ? (
                    <TopicData topic={topicLookup[item.payload.topic]} draggableProps={props} />
                  ) : null
                }
              </Draggable>
            ))}
            {items.length === 0 && (
              <Text py={2} textAlign="center">
                No topics match your search.{' '}
                <Link textDecoration="underline" onClick={() => setFilter({ search: '' })}>
                  Clear search
                </Link>
              </Text>
            )}
          </VStack>
        </Box>
      )}
      <Flex flex={1} display={hasSearch ? 'none' : undefined}>
        <TopicPickerWithFilter
          filter={filter}
          setFilter={setFilter}
          onSelectSubstrand={selectSubstrand}
          hideCurriculumSelection={true}
        >
          <VStack spacing={1} alignItems="stretch" px={4} mb={2}>
            {!hasSearch &&
              items.map(item => (
                <Draggable key={item.workUnitId} id={item.workUnitId}>
                  {props =>
                    item.payload.oneofKind === 'topic' ? (
                      <TopicData topic={topicLookup[item.payload.topic]} draggableProps={props} />
                    ) : null
                  }
                </Draggable>
              ))}
            {items.length === 0 && <Text py={2}>There are no topics in this substrand</Text>}
          </VStack>
        </TopicPickerWithFilter>
      </Flex>
    </>
  );
};
