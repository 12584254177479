import { Box, Button, HStack, IconButton, Spacer, Text } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SkillSupportMaterial } from '@sparx/api/apis/sparx/content/v2/skill';
import {
  context as SparxQuestionContext,
  MarkdownNode,
  SparxQuestionContextDefaultValues,
  SparxQuestionWrapper,
} from '@sparx/question';
import { useClientEvent } from 'components/ClientEventProvider';
import { getAssetUrl } from 'components/uploadedasset/UploadedAsset';
import { AnimatePresence } from 'motion/react';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { AnimatedPage } from './AnimatedPage';

interface SupportMaterialProps {
  material: SkillSupportMaterial;
  onClose?: () => void;
  fontSize?: number;
  inline?: boolean;
}

export const SupportMaterial = ({ material, onClose, fontSize, inline }: SupportMaterialProps) => {
  const { sendEvent } = useClientEvent();
  const slides =
    material.text
      .replace(/\$\\ \$/g, '')
      .split(/={4}/g)
      .map(s => s.trim()) || [];

  const [reverse, setReverse] = useState(false);
  const [slide, _setSlide] = useState(0);
  const setSlide = (newSlide: number) => {
    _setSlide(newSlide);
    setReverse(newSlide < slide);
  };

  const slideContent = <SlideContent content={slides[slide]} fontSize={fontSize} />;

  const navButtons = slides.length > 1 && (
    <HStack spacing={1}>
      <IconButton
        aria-label="Previous"
        isDisabled={slide === 0}
        onClick={() => slide > 0 && setSlide(slide - 1)}
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
      />
      <Box width={2} />
      <Text fontWeight="bold">Step:</Text>
      <Box width={2} />
      {Array(slides.length)
        .fill(1)
        .map((_, i) => (
          <Button key={i} onClick={() => setSlide(i)} colorScheme={i === slide ? 'blue' : 'gray'}>
            {i + 1}
          </Button>
        ))}
      <IconButton
        aria-label="Next"
        isDisabled={slide >= slides.length - 1}
        onClick={() => slide < slides.length && setSlide(slide + 1)}
        icon={<FontAwesomeIcon icon={faChevronRight} />}
      />
    </HStack>
  );

  let content;
  if (!inline) {
    content = (
      <Box flex={1} overflowY="auto" overflowX="hidden" position="relative">
        <AnimatePresence mode="wait" custom={reverse} initial={false}>
          <AnimatedPage reverse={reverse} key={slide}>
            {slideContent}
          </AnimatedPage>
        </AnimatePresence>
      </Box>
    );
  } else {
    content = slideContent;
  }

  return (
    <Box overflow="hidden" flex={1} display="flex" flexDirection="column" height="100%">
      <SparxQuestionContext.Provider
        value={{
          ...SparxQuestionContextDefaultValues,
          getAssetUrl,
          sendAnalyticEvent: (action, labels) =>
            sendEvent({ category: 'question', action }, labels),
        }}
      >
        {content}
      </SparxQuestionContext.Provider>
      {(onClose || navButtons) && (
        <Box
          display="flex"
          alignItems="center"
          borderTopWidth={2}
          borderColor="grey.50"
          pt={4}
          mt={4}
        >
          {navButtons}
          <Spacer />
          {onClose && <Button onClick={onClose}>Close</Button>}
        </Box>
      )}
    </Box>
  );
};

interface SlideContentProps {
  content: string;
  onUpdateHeight?: (height: number) => void;
  fontSize?: number;
}

const SlideContent = ({ content, onUpdateHeight, fontSize }: SlideContentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      onUpdateHeight?.(ref.current.offsetHeight + 5 /* small offset */);
    }
  }, [ref]);

  return (
    <SparxQuestionWrapper ref={ref} font={{ fontSize }}>
      <MarkdownNode>{content}</MarkdownNode>
    </SparxQuestionWrapper>
  );
};
