import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { getSchool } from '@sparx/query/schools-service';
import { useQuery } from '@tanstack/react-query';
import { dashboardDataClient } from 'api';
import { getSchoolID } from 'api/sessions';
import { format } from 'date-fns';
import React, { useState } from 'react';
import CountUp from 'react-countup';
import { Carousel, useCarouselItemContext } from 'views/teacherhome/Carousel';
import { DownloadableElement } from 'views/teacherhome/DownloadableElement';
import clock from 'views/teacherhome/images/clock.svg';
import comingSoon from 'views/teacherhome/images/comingsoon.svg';
import questionPatternLeft from 'views/teacherhome/images/questions_pattern_left.svg';
import questionPatternRight from 'views/teacherhome/images/questions_pattern_right.svg';

export const UsageStats = () => {
  const { data: school } = getSchool.useQuery();
  const { data, error, isSuccess } = useQuery({
    queryKey: ['school', 'usage'],
    queryFn: async () =>
      dashboardDataClient.getUsageData({
        schoolName: `schools/${await getSchoolID()}`,
      }).response,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    retry: (_, err) => {
      // Only retry if it's not a NOT_FOUND error
      return !(err instanceof RpcError && err.code === 'NOT_FOUND');
    },
  });

  // Don't show stats for schools with low numbers
  const showUsageStats =
    (data?.statistics?.totalLearningHours || 0) > 10 &&
    (data?.statistics?.totalQuestionsCorrect || 0) > 100;

  // 50% chance of the order being reversed in the usage stats
  const [reverse] = useState(Math.random() < 0.5);

  let pages = [<LoadingStats key="loading" />];
  if ((error instanceof RpcError && error.code === 'NOT_FOUND') || (isSuccess && !showUsageStats)) {
    pages = [<NoDataPanel key="no data" />];
  } else if (error) {
    pages = [
      <ErrorPanel key="error" message="Error loading Sparx usage, please try again later" />,
    ];
  } else if (data && showUsageStats && school) {
    pages = [
      <DownloadableElement
        key="answers"
        downloadable={
          <CorrectAnswersPanel
            value={data.statistics?.totalQuestionsCorrect || 0}
            downloadable={true}
          />
        }
        filename={`${school.displayName} - Answers this year (${format(new Date(), 'MM-dd-yyyy')})`}
      >
        <CorrectAnswersPanel value={data.statistics?.totalQuestionsCorrect || 0} />
      </DownloadableElement>,
      <DownloadableElement
        key="hours"
        downloadable={
          <HoursPanel value={data.statistics?.totalLearningHours || 0} downloadable={true} />
        }
        filename={`${school.displayName} - Hours learning this year (${format(
          new Date(),
          'MM-dd-yyyy',
        )})`}
      >
        <HoursPanel value={data.statistics?.totalLearningHours || 0} />
      </DownloadableElement>,
    ];
    // If we're reversing it do that now
    if (reverse) pages.reverse();
  }
  return <Carousel title="Sparx Science usage" icon={faArrowTrendUp} pages={pages} />;
};

const LoadingStats = () => (
  <Flex alignItems="center" justifyContent="center" flex={1} p={5} bg="gray.50">
    <Spinner size="lg" colorScheme="buttonTeal" />
  </Flex>
);

const ErrorPanel = ({ message }: { message: string }) => (
  <Flex alignItems="center" justifyContent="center" flex={1} bg="gray.50">
    <Text color="gray.500" fontSize="lg" p={5} textAlign="center">
      {message}
    </Text>
  </Flex>
);

const NoDataPanel = () => (
  <Flex alignItems="center" justifyContent="space-evenly" flex={1} bg="gray.50">
    <Text color="gray.500" fontSize="lg" textAlign="left" maxWidth={'50%'}>
      Information will appear once students are underway...
    </Text>
    <Image src={comingSoon} maxHeight={'min(80px, 80%)'} maxWidth="25%" borderRadius="sm" />
  </Flex>
);

interface HoursPanelProps {
  value: number;
  downloadable?: boolean;
}

export const HoursPanel = ({ value, downloadable }: HoursPanelProps) => {
  const { visible } = useCarouselItemContext();

  return (
    <Flex
      color="gray.900"
      bgGradient="linear-gradient(to left, teal.500 0, green.400 100%)"
      pl={9}
      pr={6}
      py={6}
      alignItems="center"
      fontSize="lg"
      textShadow="0 1px 0 rgba(255, 255, 255, 0.4)"
      width="100%"
      height="100%"
    >
      <Box flex={1}>
        <Text lineHeight="1em">Students in your school have completed</Text>
        <Text
          display="inline-block"
          fontSize="4xl"
          fontWeight="bold"
          my={2}
          color="white"
          textShadow="0 2px 5px rgba(0, 0, 0, 0.4)"
        >
          {!downloadable && visible ? (
            <CountUp
              delay={0.5}
              end={value}
              duration={1}
              separator=","
              easingFn={easingFn}
              suffix=" hours"
            />
          ) : (
            <>{value.toLocaleString()} hours</>
          )}
        </Text>
        <Text lineHeight="1em">
          of personalised learning on <span style={{ whiteSpace: 'nowrap' }}>Sparx Science</span>{' '}
          <span style={{ whiteSpace: 'nowrap' }}>this year!</span>
        </Text>
      </Box>
      <Image width="30%" src={clock} alt="" />
    </Flex>
  );
};

interface CorrectAnswersPanelProps {
  value: number;
  downloadable?: boolean;
}

export const CorrectAnswersPanel = ({ value, downloadable }: CorrectAnswersPanelProps) => {
  const { visible } = useCarouselItemContext();
  const [finished, setFinished] = useState(downloadable);

  return (
    <Flex
      color="gray.900"
      bgGradient="radial(circle at center, teal.300 0, teal.500 100%)"
      alignItems="center"
      fontSize="lg"
      position="relative"
      flexDirection="column"
      width="100%"
      height="100%"
    >
      <Flex flexDirection="column" alignItems="center" textAlign="center" width="100%" flex={1}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          flex={1}
          width="100%"
        >
          <Text
            fontSize="5xl"
            fontWeight="bold"
            mb={1}
            color="white"
            textShadow="0 2px 5px rgba(0, 0, 0, 0.4)"
          >
            {!downloadable && visible ? (
              <CountUp
                delay={0.5}
                end={value}
                duration={1}
                separator=","
                onEnd={() => setFinished(true)}
                easingFn={easingFn}
              />
            ) : (
              value.toLocaleString()
            )}
          </Text>
          <Text lineHeight="1em" maxWidth="50%" textShadow="0 1px 0 rgba(255, 255, 255, 0.4)">
            questions answered correctly in Sparx Science so far{' '}
            <span style={{ whiteSpace: 'nowrap' }}>this year</span>
          </Text>
        </Flex>
      </Flex>
      <Flex position="absolute" inset="0 0 0 0">
        <Image
          height="100%"
          src={questionPatternLeft}
          alt=""
          opacity={finished ? 1 : 0}
          transform={finished ? 'translateX(0)' : 'translateX(25%)'}
          transition="all 0.5s var(--smooth-transition)"
        />
        <Box flex={1} />
        <Image
          height="100%"
          src={questionPatternRight}
          alt=""
          opacity={finished ? 1 : 0}
          transform={finished ? 'translateX(0)' : 'translateX(-25%)'}
          transition="all 0.5s var(--smooth-transition)"
        />
      </Flex>
    </Flex>
  );
};

const easingFn = (t: number, b: number, c: number, d: number) =>
  c * ((t = t / d - 1) * t * t * t * t + 1) + b;
