import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useUserType } from 'api/sessions';

import { useIndependentLearningContext } from '../Context';
import combinedIcons from '../images/icons_subject_light.svg';
import { ILChevron } from './Misc';

export const PersonalPractice = () => {
  const { isTeacher } = useUserType();
  const { createTask, userState } = useIndependentLearningContext();

  // Can only do personal practice if topics have been assigned (or they are a teacher who will get a demo version)
  const hasPreviousTopics = userState.previousTopics.length > 0 || isTeacher;

  return (
    <ILCard title="Personal practice" image={combinedIcons}>
      <Text mb={3} maxWidth="75%" position="relative">
        Let Sparx choose practice questions for you, based on what you&apos;ve done in your
        homework.
      </Text>
      <Flex grow={1} />
      {hasPreviousTopics ? (
        <Button
          onClick={e => {
            e.stopPropagation();
            createTask();
          }}
          rightIcon={<ILChevron />}
          maxWidth={200}
        >
          Start new
        </Button>
      ) : (
        <Text color="gray.800" maxWidth="75%">
          Your personal practice isn&apos;t ready yet. Why not choose a topic below instead.
        </Text>
      )}
    </ILCard>
  );
};

export const ILCard = ({
  title,
  image,
  children,
}: {
  title: string;
  image?: string;
  children: React.ReactNode;
}) => (
  <Box
    boxShadow="elevationLow"
    borderRadius="lg"
    p={{ base: 6, md: 8 }}
    flex={1}
    flexDirection="column"
    display="flex"
    position="relative"
    bgGradient="linear-gradient(97deg, teal.600 15%, #006573 95%)"
    color="white"
  >
    {image && (
      <Box
        position="absolute"
        top={0}
        right={6}
        height="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Image src={image} height="90%" maxWidth={{ base: '100px', md: '100px', lg: '150px' }} />
      </Box>
    )}
    <Heading color="white" fontSize="xl" mb={3}>
      {title}
    </Heading>
    {children}
  </Box>
);
