import { Box, Center, Text } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stepIndexToDisplayNum } from '@sparx/packageactivity';
import { AnimatePresence, motion } from 'motion/react';
import { PropsWithChildren } from 'react';

interface MultiStepWrapperProps {
  correct: boolean;
  index: number;
  maxStep: number;
  solidLine?: boolean;
  compact?: boolean;
  sideComponent?: React.ReactNode;
  stepToDisplayNum?: {
    [key: number]: number;
  };
}

export const MultiStepWrapper = ({
  children,
  correct,
  index,
  solidLine,
  maxStep,
  compact,
  sideComponent,
  stepToDisplayNum,
}: PropsWithChildren<MultiStepWrapperProps>) => (
  <>
    <Box mr={[3, 4]} display="flex" flexDirection="column" alignItems="center">
      <Box w={5} h={5} bg={`gray.300`} borderRadius="full" position="relative">
        <AnimatePresence initial={false}>
          {correct && (
            <motion.div
              initial={{ opacity: 0, transform: 'scale(3, 3)' }}
              animate={{ opacity: 1, transform: 'scale(1, 1)' }}
              transition={{ duration: 1, type: 'spring', bounce: 0.5 }}
              style={{ position: 'absolute', inset: '0 0 0 0' }}
            >
              <Center
                bg="green.400"
                color="white"
                fontSize="sm"
                borderRadius="full"
                height="100%"
                width="100%"
              >
                <FontAwesomeIcon icon={faCheck} />
              </Center>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <Box
        w={1}
        flex={1}
        transition="background-image 5s ease-in-out"
        bgGradient={
          !solidLine ? `linear(to-b, gray.300, transparent)` : `linear(to-b, gray.300, gray.300)`
        }
      />
    </Box>
    <Box width="100%" pb={5} mb={5}>
      <Box display="flex" alignItems="center" mb={4} mt={-0.5}>
        <Text fontWeight="bold" fontSize={compact ? 'md' : ['sm', 'md', 'lg']}>
          Step {stepIndexToDisplayNum(stepToDisplayNum, index)} /{' '}
          {stepIndexToDisplayNum(stepToDisplayNum, maxStep)}
        </Text>
        <Box h={1} bg="gray.200" ml={4} flex={1} />
        {sideComponent}
      </Box>
      {children}
    </Box>
  </>
);
