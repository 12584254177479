import classNames from 'classnames';
import { diffWords } from 'diff';
import { useMemo } from 'react';

import styles from '../question/SparxQuestion.module.css';
import { GapEvaluation, LayoutElementProps } from '../question/SparxQuestionContext';
import { ITextFieldElement } from '../question/types';

interface TextDiffComparisonProps extends LayoutElementProps<ITextFieldElement> {
  value?: string;
  inputRef?: HTMLElement | null;
}

export const TextDiffComparison = ({ element, value, inputRef }: TextDiffComparisonProps) => (
  <div
    onClick={() => {
      inputRef?.focus();
      inputRef?.scrollIntoView({ behavior: 'smooth' });
    }}
    className={classNames(styles.TextField, styles.TextFieldText, styles.TextAreaDiffComparison)}
  >
    <DiffView value={value} original={element.diff} />
  </div>
);

export const TextDiffDetail = ({
  evaluation,
  originalValue,
  value,
}: {
  evaluation: GapEvaluation;
  originalValue: string;
  value: string;
}) => {
  const incorrectDetail =
    evaluation.detail?.filter(
      d =>
        // Filter out only ones that were incorrect
        d.awardedMarks < d.availableMarks &&
        // If answer was correct, filter out any alternatives that only differ in cases
        !(evaluation.correct && d.feedback.toLowerCase() === value.toLowerCase()),
    ) || [];
  if (incorrectDetail.length === 0) {
    return null;
  }

  const title = `${evaluation.correct ? 'Alternative' : 'Correct'} answer${
    incorrectDetail.length > 1 ? 's' : ''
  }`;
  return (
    <div className={styles.DiffResult}>
      <strong className={styles.DiffResultHeader}>{title}:</strong>
      {incorrectDetail.map((d, i) => (
        <div key={i} className={styles.DiffResultDetail} style={{ animationDelay: `${i / 5}s` }}>
          <div className={styles.MarkingResultFeedback}>
            <DiffView value={d.feedback} original={originalValue} />
          </div>
        </div>
      ))}
    </div>
  );
};

const DiffView = ({ value, original }: { value?: string; original?: string }) => {
  const diff = useMemo(() => diffWords(original || '', value || ''), [original, value]);
  return (
    <span className={styles.Diff}>
      {diff.map((part, index) => (
        <span
          className={part.added ? styles.DiffAdded : part.removed ? styles.DiffRemoved : undefined}
          key={index}
        >
          {part.value}
        </span>
      ))}
    </span>
  );
};
