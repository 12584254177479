import { Box, HStack, LinkBox, LinkOverlay, Spacer, Text } from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LessonState } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { readableStaffNames } from '@sparx/staff-manager';
import { useGroups } from 'api/school';
import { useStaffLookup } from 'api/staff';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { motion } from 'motion/react';
import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { LessonCode } from 'views/lessons/LessonView';

export const LessonRow = ({ lesson }: { lesson: LessonState }) => {
  const [searchParams] = useSearchParams();
  const groupSearchParam = searchParams.get('group');

  const foundStaff = useStaffLookup({ suspense: true }).data?.[lesson.creatorUserId];
  const creatorName =
    lesson.creatorUserId === 'sparx'
      ? 'Sparx'
      : foundStaff
        ? (() => {
            const { realName, fallback } = readableStaffNames(foundStaff);
            return realName || fallback;
          })()
        : 'Unknown';

  const { data: groups } = useGroups({ suspense: true });
  const classGroups = useMemo(
    () => lesson.groupNames.map(gn => groups?.find(g => g.name === gn)).filter(g => !!g) as Group[],
    [lesson, groups],
  );

  const lessonID = lesson.lessonName.split('/')[3];
  const link = !lesson.endedTimestamp
    ? `/teacher/lessons/${lessonID}`
    : `/teacher/lessons/archive/${lessonID}`;

  return (
    <LinkBox
      as={motion.div}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      bg={'white'}
      color={'gray.900'}
      _hover={{
        bg: 'gray.50',
      }}
      boxShadow="elevationLow"
      borderRadius="md"
    >
      <HStack spacing={4} p={4}>
        <LessonCode code={lesson.lessonCode} inactive={Boolean(lesson.endedTimestamp)} />
        <Box pl={1}>
          <HStack spacing={2} display="inline-flex">
            {classGroups.map(g => (
              <Box
                key={g.name}
                px={2}
                borderRadius="sm"
                fontSize="sm"
                py={0.5}
                bg="blue.50"
                color="blue.700"
                fontWeight="bold"
              >
                {g.displayName}
              </Box>
            ))}
            <Text fontWeight="bold">
              <LinkOverlay
                as={Link}
                to={link}
                state={groupSearchParam ? { group: groupSearchParam } : undefined}
              >
                {lesson.displayName || 'Untitled Lesson'}
              </LinkOverlay>
            </Text>
          </HStack>
          <Text color="gray.500" fontSize="sm">
            Created by <strong data-sentry-mask>{creatorName}</strong> at{' '}
            <PrettyTimestamp>{lesson.createdTimestamp}</PrettyTimestamp>
          </Text>
          {lesson.endedTimestamp && (
            <Text color="gray.500" fontSize="sm">
              Ended at <PrettyTimestamp>{lesson.createdTimestamp}</PrettyTimestamp>
            </Text>
          )}
        </Box>
        <Spacer />
        <FontAwesomeIcon fixedWidth={true} icon={faChevronRight} />
      </HStack>
    </LinkBox>
  );
};
