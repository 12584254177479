import { CheckIcon, ChevronDownIcon, ChevronUpIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import selectStyles from '@sparx/sparx-design/shared-styles/Select.module.css';
import classNames from 'classnames';
import { useMemo } from 'react';

import styles from './ConflictsModal.module.css';

interface ConflictClassSelectProps {
  // The classes to choose from
  classes: Class[];
  // The Wonde ID of the selected class
  selectedClassWondeID?: string;
  // Function to call when a class is selected
  onSelectClass: (classWondeID: string) => void;
  // Whether the select is disabled
  disabled?: boolean;
}

/**
 * Select component for choosing which class a student should be assigned to
 * Also used for bulk selecting a class for selected students
 * @param classes The classes to choose from
 * @param selectedClassWondeID The Wonde ID of the selected class
 * @param onSelectClass Function to call when a class is selected
 * @param disabled Whether the select is disabled
 * @constructor
 */
export const ConflictsClassSelect = ({
  classes,
  selectedClassWondeID,
  onSelectClass,
  disabled,
}: ConflictClassSelectProps) => {
  // Sort the classes by name:
  const sortedClasses = useMemo(
    () => classes.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
    [classes],
  );

  return (
    <Select.Root
      value={selectedClassWondeID || ''}
      onValueChange={(value: string) => onSelectClass(value)}
      defaultValue={undefined}
    >
      <Select.Trigger
        className={classNames(
          selectStyles.SelectTrigger,
          accessibilityStyles.FocusTarget,
          styles.SelectTrigger,
          { [styles.SelectDisabled]: disabled },
        )}
        aria-label="class to use with Sparx"
        disabled={disabled}
      >
        <Select.Value placeholder="Select class">
          {classes.find(c => c.id === selectedClassWondeID)?.name ?? 'Select class'}
        </Select.Value>
        <Select.Icon>
          <TriangleDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={classNames(styles.Select, selectStyles.SelectContent)}
          position="popper"
        >
          <Select.ScrollUpButton>
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className={styles.SelectDropdown}>
            {sortedClasses.map(c => (
              <Select.Item
                className={classNames(selectStyles.SelectItem, styles.SelectItem)}
                value={c.id}
                key={c.id}
              >
                <Select.ItemText>{c.name}</Select.ItemText>
                <Select.ItemIndicator className={selectStyles.SelectItemIndicator}>
                  <CheckIcon />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
