import { SparxQuestion, SparxQuestionProps } from '@sparx/question';
import { useClientEvent } from 'components/ClientEventProvider';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import { useSparxQuestionColours } from 'views/task/ActivityDelivery';

type partial<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export const SparxScienceQuestion = (
  props: partial<
    SparxQuestionProps,
    'getAssetUrl' | 'getUploadedAsset' | 'sendAnalyticEvent' | 'inlineSlotsAndNumberFields'
  >,
) => {
  const { sendEvent } = useClientEvent();
  const colours = useSparxQuestionColours();
  return (
    <SparxQuestion
      getUploadedAsset={uploadedAssetProvider}
      getAssetUrl={getAssetUrl}
      sendAnalyticEvent={(action, labels) => sendEvent({ category: 'question', action }, labels)}
      inlineSlotsAndNumberFields={true}
      colours={colours}
      {...props}
    />
  );
};
