export const LevelBadge5 = ({ level }: { level: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={'100%'} fill="none" viewBox="0 0 284 249">
      <path
        d="M233.071 95.9957C245.791 97.1957 258.481 99.0457 271.091 101.526C273.121 101.926 274.851 100.066 274.231 98.1257C271.901 90.8957 269.371 83.6757 266.651 76.4757C266.321 75.6157 266.481 74.6657 267.081 73.9557C272.091 68.0957 277.281 62.3357 282.661 56.6657C284.111 55.1357 283.091 52.7157 280.781 52.2657C266.521 49.4557 252.171 47.3757 237.781 46.0157C236.091 45.8557 234.621 46.9357 234.491 48.3957C233.191 63.2957 231.891 78.1957 230.581 93.0957C230.451 94.5657 231.561 95.8557 233.051 95.9957H233.071Z"
        fill="#0A6952"
      />
      <path
        d="M50.2799 96.0056C51.7699 95.8656 52.8799 94.5656 52.7499 93.1056C51.4399 78.2056 50.1399 63.3056 48.8399 48.4056C48.7099 46.9356 47.2399 45.8656 45.5499 46.0256C31.1599 47.3856 16.8199 49.4656 2.54986 52.2756C0.249856 52.7256 -0.780143 55.1456 0.669857 56.6756C6.03986 62.3456 11.2299 68.1156 16.2499 73.9656C16.8499 74.6656 17.0099 75.6256 16.6799 76.4856C13.9599 83.6756 11.4299 90.9056 9.09986 98.1356C8.46986 100.076 10.1999 101.936 12.2399 101.536C24.8499 99.0556 37.5399 97.2056 50.2599 96.0056H50.2799Z"
        fill="#0A6952"
      />
      <path
        d="M228.742 168.98C228.742 175.88 223.892 184.41 217.972 187.94L146.972 230.31C144.292 231.91 140.962 231.91 138.282 230.31L67.2817 187.94C61.3617 184.41 56.5117 175.87 56.5117 168.98V21.8398C56.5117 14.9398 62.1517 9.2998 69.0517 9.2998H216.192C223.092 9.2998 228.732 14.9398 228.732 21.8398V168.98H228.742Z"
        fill="#C67B18"
      />
      <path
        d="M142.63 242.21C139.89 242.21 137.15 241.48 134.7 240.02L63.6995 197.65C55.6195 192.83 49.5195 182.09 49.5195 172.67V25.54C49.5195 14.77 58.2895 6 69.0595 6H216.2C226.97 6 235.74 14.77 235.74 25.54V172.68C235.74 182.1 229.64 192.83 221.56 197.66L150.56 240.03C148.11 241.49 145.37 242.22 142.63 242.22V242.21ZM63.5195 14.23C60.4695 14.23 63.5195 22.48 63.5195 25.54V172.68C63.5195 177.08 67.0895 183.38 70.8795 185.63L141.88 228C142.35 228.28 142.93 228.28 143.39 228L214.39 185.63C218.17 183.37 221.74 177.08 221.74 172.68V25.54C221.74 22.49 223.68 14.23 220.62 14.23"
        fill="#A56921"
      />
      <path
        d="M259.093 89.0901C254.973 90.2201 248.553 89.0301 234.823 94.0401C234.343 94.2101 233.863 93.7501 233.963 93.1801C234.793 88.0601 235.623 82.9501 236.453 77.8301C244.833 79.6201 253.183 81.7101 261.483 84.0801C262.183 84.2801 259.783 88.9001 259.093 89.0901Z"
        fill="#044B3A"
      />
      <path
        d="M26.9491 89.5404C36.4491 90.1804 44.4591 92.0004 53.4791 95.3004C53.8191 95.4204 54.1591 95.0904 54.0991 94.6904C53.2791 89.6204 52.4491 84.5704 51.6391 79.5004C51.5891 79.2104 51.3391 79.0204 51.0691 79.0804C42.5891 80.9104 34.1591 83.0304 25.7691 85.4504C25.2791 85.5904 26.4591 89.4004 26.9591 89.5404H26.9491Z"
        fill="#044B3A"
      />
      <path
        d="M142.63 238.21C139.89 238.21 137.15 237.48 134.7 236.02L63.6995 193.65C55.6195 188.83 49.5195 178.09 49.5195 168.67V19.54C49.5195 8.77 58.2895 0 69.0595 0H216.2C226.97 0 235.74 8.77 235.74 19.54V168.68C235.74 178.1 229.64 188.83 221.56 193.66L150.56 236.03C148.11 237.49 145.37 238.22 142.63 238.22V238.21ZM69.0595 16C66.0095 16 63.5195 18.48 63.5195 21.54V168.68C63.5195 173.08 67.0895 179.38 70.8695 181.63L141.87 224C142.33 224.28 142.92 224.28 143.38 224L214.38 181.63C218.16 179.37 221.73 173.08 221.73 168.68V21.54C221.73 18.49 219.25 16 216.19 16H69.0595Z"
        fill="url(#paint0_linear_532_3058)"
      />
      <path
        opacity="0.15"
        d="M206.599 16.4397C206.599 16.4397 189.379 102.06 91.7188 193.92C91.7188 193.24 142.639 224.62 142.639 224.62L215.049 181.78C215.049 181.78 221.699 176.69 221.699 166.5V22.8097C221.699 22.8097 222.539 16.4297 215.649 16.4297H206.599V16.4397Z"
        fill="url(#paint1_radial_532_3058)"
      />
      <path
        d="M257.36 89.3902C181.23 78.8502 104.02 78.8502 27.8897 89.3902C26.1597 89.6302 24.4997 88.4402 24.1797 86.7202C21.4697 72.0202 18.7597 57.3202 16.0497 42.6302C15.7297 40.9102 16.9897 39.3002 18.8597 39.0402C100.98 27.6702 184.28 27.6702 266.41 39.0402C268.28 39.3002 269.53 40.9102 269.22 42.6302C266.51 57.3302 263.8 72.0302 261.08 86.7202C260.76 88.4402 259.1 89.6302 257.37 89.3902H257.36Z"
        fill="url(#paint2_linear_532_3058)"
      />
      <path
        opacity="0.3"
        d="M235.76 94.2495C173.85 87.2295 111.34 87.2295 49.4295 94.2495C49.3695 91.7095 49.3195 89.1595 49.2695 86.6195C111.27 79.6595 173.86 79.6495 235.87 86.6095C235.84 89.1495 235.8 91.6995 235.77 94.2395L235.76 94.2495Z"
        fill="#0E1935"
      />
      <path
        opacity="0.2"
        d="M18.8672 41.0598C100.987 29.6598 184.297 29.6598 266.417 41.0598"
        stroke="white"
        strokeWidth="2.42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.3"
        d="M232.912 10.8003C228.572 5.31032 221.932 1.95032 214.972 2.37032H211.922L187.492 2.44032C154.572 2.62032 104.242 2.47032 71.4623 2.31032C69.9523 2.31032 67.1223 2.20032 65.5823 2.65032C57.1523 4.37032 51.8923 10.3503 49.6523 18.3503C50.1323 9.63032 56.5623 2.43032 65.1623 0.690317C66.8723 0.220317 69.6123 0.250317 71.4323 0.260317C104.142 0.110317 154.622 -0.0296825 187.482 0.140317C191.802 0.210317 214.102 0.100317 218.112 0.290317C224.432 0.930317 230.412 4.99032 233.222 10.6003C233.332 10.7903 233.032 10.9703 232.912 10.7903V10.8003Z"
        fill="#FFF7E3"
      />
      <path
        opacity="0.48"
        d="M69.52 180.85L143.12 225.1H142.08C142.44 225.16 215.08 181.3 215.43 181.94C215.5 182.06 215.46 182.21 215.35 182.29L143.12 226.81C142.79 227.01 142.39 227 142.08 226.81C142.29 226.45 67.91 181.18 69.53 180.85H69.52Z"
        fill="#FFEB73"
      />
      <g opacity="0.67">
        <path
          d="M124.83 114.62V116.71H113.43V97.8096H115.83V114.61H124.82L124.83 114.62Z"
          fill="white"
        />
        <path
          d="M137.729 113.11C137.509 113.59 137.219 114.06 136.879 114.52C136.539 114.98 136.139 115.38 135.679 115.72C135.219 116.06 134.699 116.33 134.119 116.54C133.539 116.75 132.889 116.85 132.179 116.85C131.319 116.85 130.509 116.68 129.729 116.35C128.949 116.02 128.289 115.54 127.719 114.93C127.149 114.32 126.699 113.59 126.369 112.75C126.039 111.91 125.879 110.98 125.879 109.95C125.879 108.92 126.039 108.01 126.369 107.17C126.699 106.33 127.139 105.61 127.699 105C128.259 104.39 128.919 103.91 129.669 103.57C130.419 103.23 131.209 103.06 132.029 103.06C132.849 103.06 133.619 103.22 134.349 103.54C135.079 103.86 135.709 104.32 136.249 104.91C136.789 105.5 137.219 106.21 137.539 107.04C137.859 107.87 138.019 108.79 138.019 109.81C138.019 110.01 138.019 110.17 137.989 110.32C137.959 110.47 137.949 110.58 137.929 110.68C137.909 110.8 137.889 110.91 137.869 111.02H128.279C128.319 111.5 128.449 111.97 128.679 112.43C128.909 112.89 129.199 113.29 129.539 113.63C129.879 113.97 130.289 114.25 130.759 114.46C131.229 114.67 131.699 114.77 132.169 114.77C132.589 114.77 132.959 114.71 133.289 114.59C133.619 114.47 133.919 114.33 134.189 114.17C134.459 114.01 134.689 113.84 134.869 113.65C135.049 113.46 135.199 113.28 135.319 113.11H137.719H137.729ZM132.029 105.18C131.569 105.18 131.129 105.28 130.719 105.47C130.309 105.66 129.929 105.93 129.589 106.28C129.249 106.63 128.969 107.03 128.739 107.51C128.509 107.99 128.359 108.51 128.279 109.08H135.619C135.459 107.88 135.049 106.93 134.379 106.23C133.709 105.53 132.929 105.18 132.029 105.18Z"
          fill="white"
        />
        <path
          d="M152.117 103.21L146.707 116.72H144.617L139.207 103.21H141.607L145.667 113.57L149.707 103.21H152.107H152.117Z"
          fill="white"
        />
        <path
          d="M165.307 113.11C165.087 113.59 164.797 114.06 164.457 114.52C164.117 114.98 163.717 115.38 163.257 115.72C162.797 116.06 162.277 116.33 161.697 116.54C161.117 116.75 160.467 116.85 159.757 116.85C158.897 116.85 158.087 116.68 157.307 116.35C156.527 116.02 155.867 115.54 155.297 114.93C154.727 114.32 154.277 113.59 153.947 112.75C153.617 111.91 153.457 110.98 153.457 109.95C153.457 108.92 153.617 108.01 153.947 107.17C154.277 106.33 154.717 105.61 155.277 105C155.837 104.39 156.497 103.91 157.247 103.57C157.997 103.23 158.787 103.06 159.607 103.06C160.427 103.06 161.197 103.22 161.927 103.54C162.657 103.86 163.287 104.32 163.827 104.91C164.367 105.5 164.797 106.21 165.117 107.04C165.437 107.87 165.597 108.79 165.597 109.81C165.597 110.01 165.597 110.17 165.567 110.32C165.537 110.47 165.527 110.58 165.507 110.68C165.487 110.8 165.467 110.91 165.447 111.02H155.857C155.897 111.5 156.027 111.97 156.257 112.43C156.487 112.89 156.777 113.29 157.117 113.63C157.457 113.97 157.867 114.25 158.337 114.46C158.807 114.67 159.267 114.77 159.747 114.77C160.167 114.77 160.537 114.71 160.867 114.59C161.197 114.47 161.497 114.33 161.767 114.17C162.037 114.01 162.267 113.84 162.447 113.65C162.627 113.46 162.777 113.28 162.897 113.11H165.297H165.307ZM159.617 105.18C159.157 105.18 158.717 105.28 158.307 105.47C157.897 105.66 157.517 105.93 157.177 106.28C156.837 106.62 156.557 107.03 156.327 107.51C156.097 107.99 155.947 108.51 155.867 109.08H163.207C163.047 107.88 162.637 106.93 161.967 106.23C161.297 105.53 160.517 105.18 159.617 105.18Z"
          fill="white"
        />
        <path d="M170.998 116.719H168.738V97.8192H170.998V116.719Z" fill="white" />
      </g>
      <path
        d="M37.0355 58.3664C36.9403 57.4829 37.0355 56.613 37.3345 55.7702C37.6335 54.9275 38.0957 54.1663 38.7345 53.4867C39.3734 52.807 40.1618 52.2362 41.1133 51.774C42.0647 51.2983 43.1657 50.9992 44.4163 50.8633C45.5716 50.741 46.6319 50.8225 47.6105 51.0808C48.5892 51.3526 49.4591 51.774 50.2203 52.3721C50.9815 52.9566 51.5796 53.6906 52.0417 54.5333C52.5039 55.3896 52.7893 56.3411 52.8981 57.4014L48.6708 57.8499C48.562 56.8169 48.1678 56.0421 47.5018 55.5256C46.8222 54.9955 45.9386 54.7916 44.8376 54.9139C43.7366 55.0362 42.9075 55.3896 42.3502 55.9877C41.7929 56.5858 41.5482 57.2111 41.6162 57.8499C41.6841 58.5295 42.0376 59.0325 42.6628 59.3315C43.2881 59.6305 44.0629 59.8888 44.9736 60.0927C45.8843 60.2966 46.8765 60.5005 47.9504 60.7044C49.0242 60.9083 50.03 61.2481 50.9679 61.683C51.9058 62.1316 52.7214 62.7569 53.401 63.5588C54.0806 64.3608 54.4884 65.4754 54.6379 66.8618C54.7195 67.691 54.6107 68.5201 54.2845 69.3493C53.9583 70.1784 53.469 70.9396 52.8029 71.6464C52.1369 72.3533 51.2941 72.9513 50.3019 73.4271C49.296 73.9164 48.1678 74.229 46.8901 74.365C45.5852 74.5009 44.4163 74.4329 43.356 74.1747C42.2958 73.9164 41.3851 73.4814 40.5967 72.8834C39.8084 72.2853 39.1831 71.5513 38.7074 70.6678C38.2316 69.7842 37.9326 68.792 37.8238 67.691L42.2415 67.2288C42.3502 68.2619 42.7852 69.0638 43.5328 69.6347C44.2939 70.2192 45.259 70.4367 46.4688 70.3144C47.7737 70.1784 48.7115 69.7978 49.2824 69.1862C49.8669 68.5745 50.1116 67.8949 50.03 67.1609C49.9485 66.454 49.6087 65.9239 48.9698 65.5977C48.3445 65.2715 47.5698 64.9996 46.6591 64.7822C45.7483 64.5647 44.7561 64.3744 43.6823 64.1841C42.6084 63.9938 41.6026 63.6947 40.6647 63.2734C39.7268 62.852 38.9248 62.2675 38.2452 61.5063C37.5656 60.7451 37.1578 59.6985 37.0219 58.3392L37.0355 58.3664Z"
        fill="#0B7263"
      />
      <path
        d="M65.0648 55.2944C66.125 55.2129 67.1444 55.3488 68.1367 55.7022C69.129 56.0556 70.0125 56.5857 70.8008 57.3061C71.5892 58.0265 72.2417 58.8965 72.7446 59.9159C73.2611 60.9354 73.5602 62.0771 73.6689 63.3277C73.764 64.5782 73.6417 65.7472 73.3019 66.8482C72.9485 67.9356 72.4456 68.9006 71.7795 69.7298C71.1135 70.5589 70.3251 71.225 69.4008 71.7279C68.4765 72.2308 67.4842 72.5299 66.424 72.6114C65.7172 72.6658 65.0784 72.625 64.5482 72.5027C64.0045 72.3668 63.5288 72.2308 63.121 72.0541C62.6589 71.8502 62.2375 71.592 61.8705 71.2929L62.455 78.6601L58.2141 79L56.4062 56.1508L59.5326 55.9061L60.4025 57.51C60.7423 57.143 61.1501 56.7896 61.6258 56.4906C62.02 56.2323 62.5093 55.9876 63.0802 55.743C63.6511 55.4983 64.3036 55.3488 65.0512 55.2944H65.0648ZM60.9598 64.3335C61.0685 65.7336 61.5579 66.821 62.4414 67.5958C63.3113 68.3705 64.3579 68.7103 65.5677 68.6152C66.7774 68.52 67.7425 68.0171 68.4901 67.12C69.2377 66.2229 69.5503 65.0675 69.4416 63.6675C69.3328 62.2674 68.8435 61.18 67.96 60.4053C67.0765 59.6305 66.0434 59.2907 64.8337 59.3858C63.6239 59.481 62.6589 59.9839 61.9113 60.881C61.1637 61.7781 60.851 62.9335 60.9598 64.3335Z"
        fill="#0B7263"
      />
      <path
        d="M83.7949 71.4425C82.7347 71.4968 81.7153 71.3473 80.7366 70.9667C79.7579 70.5861 78.8744 70.0424 78.0996 69.3084C77.3248 68.5744 76.6996 67.6909 76.2102 66.6579C75.7209 65.6248 75.4355 64.483 75.3675 63.2325C75.2995 61.982 75.449 60.813 75.8161 59.7256C76.1831 58.6382 76.7132 57.6867 77.3928 56.8712C78.0724 56.0556 78.888 55.4032 79.8123 54.9138C80.7502 54.4245 81.7424 54.1527 82.8027 54.0983C83.5367 54.0575 84.2163 54.1119 84.8144 54.275C85.4124 54.4381 85.929 54.6148 86.3639 54.8187C86.8397 55.0634 87.3018 55.3488 87.7096 55.6614L88.3485 53.9624L91.4884 53.7856L92.467 70.7628L89.3271 70.9395L88.498 69.322C88.1174 69.689 87.7096 70.0152 87.261 70.3143C86.8533 70.5861 86.3639 70.8308 85.7795 71.0483C85.195 71.2658 84.5425 71.4017 83.8085 71.4425H83.7949ZM88.1038 62.4849C88.0222 61.0849 87.5465 59.9839 86.6902 59.1955C85.8338 58.4071 84.8008 58.0401 83.591 58.1081C82.3813 58.1761 81.4026 58.6518 80.6414 59.5353C79.8802 60.4189 79.5404 61.5606 79.622 62.9607C79.7035 64.3607 80.1793 65.4617 81.0356 66.2501C81.892 67.0385 82.925 67.4055 84.1347 67.3375C85.3445 67.2695 86.3232 66.7938 87.0843 65.9103C87.8455 65.0268 88.1853 63.885 88.1038 62.4849Z"
        fill="#0B7263"
      />
      <path
        d="M103.083 53.1061C103.287 53.1061 103.45 53.1061 103.599 53.1197C103.749 53.1333 103.885 53.1605 104.007 53.174C104.129 53.1876 104.252 53.2148 104.374 53.2284L104.564 57.6596C104.442 57.646 104.306 57.6188 104.156 57.6052C104.034 57.5916 103.871 57.5645 103.694 57.5509C103.504 57.5373 103.3 57.5373 103.083 57.5509C102.05 57.5916 101.22 57.9722 100.595 58.6791C99.9699 59.3859 99.6845 60.2966 99.7252 61.3976L100.106 70.4503L95.8649 70.627L95.1445 53.6362L98.2844 53.5003L99.1 55.3217C99.3854 54.9411 99.7252 54.5877 100.133 54.275C100.473 53.9896 100.881 53.7313 101.384 53.5003C101.873 53.2692 102.444 53.1469 103.083 53.1197V53.1061Z"
        fill="#0B7263"
      />
      <path
        d="M109.834 70.0561L105.212 70.192L110.676 61.3432L105.062 53.1877L109.684 53.0517L113.164 58.3121L116.331 52.8478L120.952 52.7119L115.841 61.1801L121.836 69.6891L117.214 69.825L113.34 64.2113L109.82 70.0425L109.834 70.0561Z"
        fill="#0B7263"
      />
      <path
        d="M130.279 52.7526C130.279 51.8691 130.455 51.0128 130.836 50.1972C131.216 49.3816 131.747 48.6748 132.44 48.0632C133.133 47.4515 133.989 46.9621 134.982 46.5816C135.974 46.201 137.102 46.0107 138.353 45.9971C139.508 45.9971 140.568 46.1602 141.52 46.5272C142.471 46.8942 143.3 47.3971 143.994 48.0632C144.687 48.7292 145.23 49.504 145.597 50.4011C145.978 51.2982 146.168 52.2769 146.182 53.3371L141.927 53.3779C141.927 52.3448 141.601 51.5293 140.976 50.9448C140.351 50.3603 139.494 50.0749 138.38 50.0749C137.265 50.0749 136.409 50.3603 135.797 50.904C135.186 51.4477 134.886 52.0458 134.886 52.6846C134.886 53.3779 135.199 53.908 135.784 54.2614C136.382 54.6284 137.116 54.9546 138.013 55.2401C138.896 55.5255 139.875 55.8245 140.922 56.1372C141.968 56.4498 142.947 56.8712 143.83 57.4013C144.728 57.9314 145.462 58.6382 146.073 59.5081C146.671 60.3781 146.984 61.5199 146.984 62.9199C146.984 63.7626 146.794 64.5782 146.399 65.3666C146.005 66.1549 145.434 66.8753 144.7 67.5006C143.966 68.1395 143.083 68.6424 142.036 69.0366C140.99 69.4308 139.834 69.6347 138.543 69.6347C137.238 69.6347 136.069 69.4715 135.05 69.1045C134.03 68.7375 133.147 68.221 132.426 67.555C131.706 66.8889 131.149 66.087 130.754 65.1627C130.36 64.2384 130.17 63.2325 130.156 62.1179L134.587 62.0772C134.587 63.1102 134.954 63.9529 135.648 64.6054C136.341 65.2578 137.292 65.5705 138.502 65.5569C139.807 65.5569 140.772 65.2578 141.411 64.7005C142.05 64.1432 142.362 63.4908 142.349 62.7568C142.349 62.0364 142.036 61.4927 141.452 61.0985C140.854 60.7043 140.12 60.3645 139.223 60.0654C138.339 59.7664 137.36 59.4674 136.314 59.1819C135.267 58.8965 134.288 58.5023 133.405 57.9858C132.508 57.4692 131.774 56.8168 131.176 55.9877C130.578 55.1721 130.279 54.0847 130.265 52.7254L130.279 52.7526Z"
        fill="#0B7263"
      />
      <path
        d="M165.09 63.2326C164.886 64.0753 164.546 64.8773 164.07 65.652C163.594 66.4268 163.01 67.1065 162.317 67.7045C161.623 68.3026 160.835 68.7784 159.938 69.1318C159.054 69.4852 158.103 69.6483 157.084 69.6483C155.928 69.6483 154.841 69.4036 153.821 68.9415C152.802 68.4793 151.905 67.8541 151.13 67.0521C150.355 66.2637 149.743 65.3394 149.295 64.2792C148.846 63.2326 148.629 62.1044 148.629 60.8946C148.629 59.6849 148.874 58.5567 149.336 57.5237C149.798 56.477 150.423 55.5663 151.198 54.7779C151.986 53.9896 152.883 53.3779 153.903 52.9293C154.922 52.4808 156.023 52.2633 157.179 52.2633C158.185 52.2633 159.136 52.4536 160.02 52.8206C160.903 53.1876 161.691 53.6769 162.371 54.275C163.051 54.8867 163.635 55.5799 164.097 56.3547C164.559 57.1295 164.886 57.945 165.076 58.7878L160.821 58.7606C160.631 58.312 160.359 57.9178 160.02 57.578C159.72 57.279 159.34 56.9935 158.878 56.7353C158.416 56.477 157.831 56.3411 157.138 56.3411C155.928 56.3411 154.922 56.7489 154.093 57.578C153.264 58.4072 152.856 59.5218 152.843 60.9354C152.843 62.3354 153.237 63.4636 154.039 64.3064C154.841 65.1491 155.847 65.5705 157.056 65.5841C157.75 65.5841 158.32 65.4753 158.796 65.2307C159.272 64.986 159.653 64.7142 159.952 64.4287C160.305 64.0889 160.577 63.6811 160.767 63.219L165.022 63.2462L165.09 63.2326Z"
        fill="#0B7263"
      />
      <path
        d="M171.633 69.6619L167.379 69.5803L167.692 52.5759L171.946 52.6575L171.633 69.6619ZM169.961 50.4011C169.323 50.4011 168.765 50.1428 168.29 49.6535C167.814 49.1642 167.583 48.5933 167.596 47.9544C167.596 47.3156 167.855 46.7583 168.344 46.2825C168.833 45.8068 169.404 45.5757 170.043 45.5893C170.682 45.6029 171.239 45.8476 171.715 46.3369C172.191 46.8262 172.422 47.3971 172.408 48.036C172.408 48.6748 172.15 49.2321 171.661 49.7079C171.171 50.1836 170.6 50.4147 169.961 50.4011Z"
        fill="#0B7263"
      />
      <path
        d="M190.388 64.9996C190.143 65.6384 189.79 66.2637 189.314 66.889C188.838 67.5142 188.268 68.0715 187.601 68.5609C186.935 69.0502 186.188 69.4308 185.345 69.7298C184.516 70.0289 183.619 70.1512 182.654 70.124C181.498 70.0832 180.411 69.825 179.405 69.3356C178.399 68.8463 177.516 68.1939 176.768 67.3919C176.021 66.5763 175.422 65.6384 175.001 64.5782C174.58 63.518 174.389 62.3898 174.417 61.1801C174.457 59.9703 174.716 58.8557 175.205 57.8227C175.694 56.7896 176.333 55.8925 177.135 55.1313C177.937 54.3701 178.848 53.7721 179.894 53.3507C180.927 52.9293 182.028 52.739 183.184 52.7662C184.298 52.807 185.331 53.0381 186.31 53.4866C187.289 53.9352 188.132 54.5604 188.838 55.3352C189.559 56.1236 190.116 57.0615 190.51 58.1489C190.904 59.2363 191.081 60.4189 191.054 61.6966C191.054 61.9005 191.027 62.0908 191 62.2675C190.972 62.4578 190.945 62.6345 190.904 62.7976C190.877 62.9879 190.837 63.1782 190.809 63.3549L178.984 62.9879C179.16 63.8306 179.555 64.551 180.18 65.1355C180.805 65.72 181.675 66.0326 182.776 66.0734C183.347 66.087 183.823 66.0462 184.203 65.9375C184.584 65.8287 184.924 65.6928 185.196 65.5297C185.495 65.3394 185.753 65.1219 185.957 64.8908L190.388 65.0268V64.9996ZM183.062 56.8304C181.947 56.7896 181.036 57.0887 180.316 57.7003C179.595 58.3256 179.12 59.0868 178.889 60.0247L187.017 60.2829C186.854 59.3451 186.419 58.5431 185.739 57.877C185.06 57.211 184.163 56.8712 183.048 56.8304H183.062Z"
        fill="#0B7263"
      />
      <path
        d="M202.794 53.5682C203.8 53.6226 204.724 53.8265 205.54 54.2071C206.355 54.5877 207.049 55.1042 207.633 55.7566C208.204 56.4091 208.639 57.1975 208.924 58.0946C209.21 58.9917 209.332 59.9976 209.264 61.1121L208.761 71.0755L204.52 70.8581L204.996 61.4384C205.05 60.3374 204.765 59.4267 204.153 58.7063C203.542 57.9994 202.712 57.6188 201.679 57.5645C200.646 57.5101 199.79 57.8091 199.097 58.448C198.404 59.0868 198.037 59.9704 197.982 61.0714L197.507 70.4911L193.266 70.2736L194.136 53.2964L197.275 53.4595L197.928 55.1586C198.363 54.8324 198.839 54.5469 199.342 54.3022C199.776 54.0983 200.279 53.9216 200.864 53.7449C201.448 53.5818 202.101 53.5139 202.821 53.5546L202.794 53.5682Z"
        fill="#0B7263"
      />
      <path
        d="M227.887 66.1822C227.629 66.9978 227.235 67.7862 226.704 68.5202C226.174 69.2542 225.549 69.9066 224.815 70.4503C224.081 71.0076 223.266 71.4154 222.355 71.7144C221.444 71.9999 220.479 72.1086 219.473 72.0407C218.318 71.9591 217.244 71.6465 216.265 71.1164C215.273 70.5862 214.43 69.893 213.71 69.0503C212.99 68.2075 212.446 67.2425 212.079 66.1686C211.698 65.0948 211.562 63.953 211.644 62.7433C211.739 61.5336 212.052 60.4326 212.582 59.4267C213.112 58.4208 213.792 57.5509 214.634 56.8169C215.463 56.0829 216.415 55.5392 217.461 55.1586C218.508 54.778 219.623 54.6285 220.778 54.7237C221.784 54.8052 222.722 55.0499 223.578 55.4712C224.435 55.8926 225.182 56.4363 225.835 57.0888C226.473 57.7412 227.004 58.4752 227.411 59.2772C227.819 60.0791 228.091 60.9219 228.227 61.7646L223.986 61.452C223.823 60.9899 223.578 60.5821 223.266 60.2151C222.994 59.9024 222.627 59.5898 222.192 59.3044C221.743 59.0053 221.172 58.8422 220.493 58.7878C219.283 58.6927 218.25 59.0461 217.38 59.8209C216.51 60.5957 216.021 61.6831 215.912 63.0831C215.803 64.4832 216.129 65.6385 216.877 66.5221C217.625 67.4192 218.603 67.9085 219.799 68.0037C220.493 68.058 221.077 67.9765 221.553 67.759C222.042 67.5551 222.436 67.3104 222.763 67.0386C223.13 66.7259 223.429 66.3318 223.66 65.8832L227.901 66.1958L227.887 66.1822Z"
        fill="#0B7263"
      />
      <path
        d="M245.233 69.1046C244.947 69.7163 244.54 70.3143 244.023 70.9124C243.506 71.4969 242.895 72.0134 242.188 72.4484C241.481 72.8833 240.706 73.2096 239.85 73.4406C238.994 73.6717 238.097 73.7397 237.132 73.6309C235.976 73.5086 234.916 73.1688 233.951 72.6115C232.986 72.0542 232.157 71.3338 231.463 70.4639C230.77 69.5939 230.254 68.6153 229.914 67.5278C229.574 66.4404 229.465 65.2987 229.588 64.0889C229.71 62.8928 230.063 61.7918 230.621 60.7995C231.178 59.8072 231.898 58.9645 232.755 58.2577C233.611 57.5509 234.576 57.0343 235.636 56.6809C236.697 56.3411 237.811 56.2188 238.967 56.3411C240.068 56.4634 241.087 56.7761 242.025 57.2926C242.963 57.8091 243.751 58.4887 244.404 59.3315C245.056 60.1742 245.545 61.1393 245.858 62.2539C246.171 63.3685 246.266 64.5646 246.13 65.8288C246.103 66.0191 246.075 66.2094 246.035 66.3997C245.994 66.5764 245.953 66.7531 245.899 66.9298C245.858 67.1201 245.804 67.3104 245.763 67.4735L234.005 66.223C234.114 67.0793 234.454 67.8133 235.038 68.4521C235.609 69.0774 236.452 69.458 237.553 69.5803C238.11 69.6347 238.6 69.6347 238.994 69.5531C239.388 69.4716 239.728 69.3629 240.013 69.2133C240.326 69.0502 240.598 68.8599 240.815 68.6288L245.233 69.091V69.1046ZM238.532 60.3917C237.431 60.2694 236.493 60.5005 235.731 61.0578C234.97 61.6151 234.44 62.3626 234.141 63.2734L242.229 64.1297C242.134 63.1782 241.767 62.3491 241.141 61.6286C240.516 60.9218 239.646 60.5005 238.545 60.3917H238.532Z"
        fill="#0B7263"
      />
      <path
        d="M37.0355 57.0072C36.9403 56.1237 37.0355 55.2537 37.3345 54.411C37.6335 53.5682 38.0957 52.8071 38.7345 52.1274C39.3734 51.4478 40.1618 50.8769 41.1133 50.4148C42.0647 49.939 43.1657 49.64 44.4163 49.504C45.5716 49.3817 46.6319 49.4633 47.6105 49.7215C48.5892 49.9934 49.4591 50.4148 50.2203 51.0128C50.9815 51.5973 51.5796 52.3313 52.0417 53.1741C52.5039 54.0304 52.7893 54.9819 52.8981 56.0421L48.6708 56.4907C48.562 55.4576 48.1678 54.6828 47.5018 54.1663C46.8222 53.6362 45.9386 53.4323 44.8376 53.5547C43.7366 53.677 42.9075 54.0304 42.3502 54.6285C41.7929 55.2265 41.5482 55.8518 41.6162 56.4907C41.6841 57.1703 42.0376 57.6732 42.6628 57.9723C43.2881 58.2713 44.0629 58.5296 44.9736 58.7334C45.8843 58.9373 46.8765 59.1412 47.9504 59.3451C49.0242 59.549 50.03 59.8888 50.9679 60.3238C51.9058 60.7723 52.7214 61.3976 53.401 62.1996C54.0806 63.0015 54.4884 64.1161 54.6379 65.5026C54.7195 66.3317 54.6107 67.1609 54.2845 67.99C53.9583 68.8192 53.469 69.5804 52.8029 70.2872C52.1369 70.994 51.2941 71.5921 50.3019 72.0678C49.296 72.5571 48.1678 72.8698 46.8901 73.0057C45.5852 73.1416 44.4163 73.0737 43.356 72.8154C42.2958 72.5572 41.3851 72.1222 40.5967 71.5241C39.8084 70.926 39.1831 70.192 38.7074 69.3085C38.2316 68.425 37.9326 67.4327 37.8238 66.3317L42.2415 65.8696C42.3502 66.9026 42.7852 67.7046 43.5328 68.2755C44.2939 68.86 45.259 69.0774 46.4688 68.9551C47.7737 68.8192 48.7115 68.4386 49.2824 67.8269C49.8669 67.2152 50.1116 66.5356 50.03 65.8016C49.9485 65.0948 49.6087 64.5647 48.9698 64.2385C48.3445 63.9122 47.5698 63.6404 46.6591 63.4229C45.7483 63.2054 44.7561 63.0151 43.6823 62.8248C42.6084 62.6345 41.6026 62.3355 40.6647 61.9141C39.7268 61.4928 38.9248 60.9083 38.2452 60.1471C37.5656 59.3859 37.1578 58.3393 37.0219 56.98L37.0355 57.0072Z"
        fill="white"
      />
      <path
        d="M65.0648 53.9352C66.125 53.8536 67.1444 53.9895 68.1367 54.3429C69.129 54.6964 70.0125 55.2265 70.8008 55.9469C71.5892 56.6673 72.2417 57.5372 72.7446 58.5567C73.2611 59.5761 73.5602 60.7179 73.6689 61.9684C73.764 63.2189 73.6417 64.3879 73.3019 65.4889C72.9485 66.5763 72.4456 67.5414 71.7795 68.3705C71.1135 69.1997 70.3251 69.8657 69.4008 70.3687C68.4765 70.8716 67.4842 71.1706 66.424 71.2522C65.7172 71.3066 65.0784 71.2658 64.5482 71.1434C64.0045 71.0075 63.5288 70.8716 63.121 70.6949C62.6589 70.491 62.2375 70.2327 61.8705 69.9337L62.455 77.3009L58.2141 77.6407L56.4062 54.7915L59.5326 54.5468L60.4025 56.1508C60.7423 55.7838 61.1501 55.4304 61.6258 55.1313C62.02 54.8731 62.5093 54.6284 63.0802 54.3837C63.6511 54.1391 64.3036 53.9895 65.0512 53.9352H65.0648ZM60.9598 62.9743C61.0685 64.3743 61.5579 65.4617 62.4414 66.2365C63.3113 67.0113 64.3579 67.3511 65.5677 67.2559C66.7774 67.1608 67.7425 66.6579 68.4901 65.7608C69.2377 64.8636 69.5503 63.7083 69.4416 62.3082C69.3328 60.9082 68.8435 59.8208 67.96 59.046C67.0765 58.2712 66.0434 57.9314 64.8337 58.0266C63.6239 58.1217 62.6589 58.6246 61.9113 59.5217C61.1637 60.4189 60.851 61.5742 60.9598 62.9743Z"
        fill="white"
      />
      <path
        d="M83.7949 70.0832C82.7347 70.1376 81.7153 69.9881 80.7366 69.6075C79.7579 69.2269 78.8744 68.6832 78.0996 67.9492C77.3248 67.2152 76.6996 66.3317 76.2102 65.2986C75.7209 64.2656 75.4355 63.1238 75.3675 61.8733C75.2995 60.6227 75.449 59.4538 75.8161 58.3664C76.1831 57.279 76.7132 56.3275 77.3928 55.5119C78.0724 54.6964 78.888 54.0439 79.8123 53.5546C80.7502 53.0652 81.7424 52.7934 82.8027 52.739C83.5367 52.6982 84.2163 52.7526 84.8144 52.9157C85.4124 53.0788 85.929 53.2555 86.3639 53.4594C86.8397 53.7041 87.3018 53.9895 87.7096 54.3022L88.3485 52.6031L91.4884 52.4264L92.467 69.4036L89.3271 69.5803L88.498 67.9628C88.1174 68.3298 87.7096 68.656 87.261 68.955C86.8533 69.2269 86.3639 69.4716 85.7795 69.689C85.195 69.9065 84.5425 70.0424 83.8085 70.0832H83.7949ZM88.1038 61.1393C88.0222 59.7392 87.5465 58.6382 86.6902 57.8499C85.8338 57.0615 84.8008 56.6945 83.591 56.7624C82.3813 56.8304 81.4026 57.3061 80.6414 58.1897C79.8802 59.0732 79.5404 60.215 79.622 61.615C79.7035 63.0151 80.1793 64.1161 81.0356 64.9044C81.892 65.6928 82.925 66.0598 84.1347 65.9918C85.3445 65.9239 86.3232 65.4481 87.0843 64.5646C87.8455 63.6811 88.1853 62.5393 88.1038 61.1393Z"
        fill="white"
      />
      <path
        d="M103.083 51.7467C103.287 51.7467 103.45 51.7467 103.599 51.7603C103.749 51.7739 103.885 51.8011 104.007 51.8147C104.129 51.8283 104.252 51.8554 104.374 51.869L104.564 56.3002C104.442 56.2866 104.306 56.2595 104.156 56.2459C104.034 56.2323 103.871 56.2051 103.694 56.1915C103.504 56.1779 103.3 56.1779 103.083 56.1915C102.05 56.2323 101.22 56.6129 100.595 57.3197C99.9699 58.0265 99.6845 58.9372 99.7252 60.0382L100.106 69.0909L95.8649 69.2676L95.1445 52.2768L98.2844 52.1409L99.1 53.9623C99.3854 53.5817 99.7252 53.2283 100.133 52.9157C100.473 52.6302 100.881 52.372 101.384 52.1409C101.873 51.9098 102.444 51.7875 103.083 51.7603V51.7467Z"
        fill="white"
      />
      <path
        d="M109.834 68.7103L105.212 68.8462L110.676 59.9974L105.062 51.8418L109.684 51.7059L113.164 56.9663L116.331 51.502L120.952 51.3661L115.841 59.8343L121.836 68.3433L117.214 68.4792L113.34 62.8655L109.82 68.6967L109.834 68.7103Z"
        fill="white"
      />
      <path
        d="M130.279 51.3934C130.279 50.5098 130.455 49.6535 130.836 48.8379C131.216 48.0224 131.747 47.3156 132.44 46.7039C133.133 46.0922 133.989 45.6029 134.982 45.2223C135.974 44.8417 137.102 44.6514 138.353 44.6378C139.508 44.6378 140.568 44.8009 141.52 45.1679C142.471 45.5349 143.3 46.0379 143.994 46.7039C144.687 47.3699 145.23 48.1447 145.597 49.0418C145.978 49.9389 146.168 50.9176 146.182 51.9778L141.927 52.0186C141.927 50.9856 141.601 50.17 140.976 49.5855C140.351 49.0011 139.494 48.7156 138.38 48.7156C137.265 48.7156 136.409 49.0011 135.797 49.5448C135.186 50.0885 134.886 50.6865 134.886 51.3254C134.886 52.0186 135.199 52.5487 135.784 52.9021C136.382 53.2691 137.116 53.5954 138.013 53.8808C138.896 54.1663 139.875 54.4653 140.922 54.7779C141.968 55.0906 142.947 55.5119 143.83 56.042C144.728 56.5721 145.462 57.279 146.073 58.1489C146.671 59.0188 146.984 60.1606 146.984 61.5606C146.984 62.4034 146.794 63.2189 146.399 64.0073C146.005 64.7957 145.434 65.5161 144.7 66.1414C143.966 66.7802 143.083 67.2831 142.036 67.6773C140.99 68.0715 139.834 68.2754 138.543 68.2754C137.238 68.2754 136.069 68.1123 135.05 67.7453C134.03 67.3783 133.147 66.8618 132.426 66.1957C131.706 65.5297 131.149 64.7277 130.754 63.8034C130.36 62.8791 130.17 61.8733 130.156 60.7587L134.587 60.7179C134.587 61.7509 134.954 62.5937 135.648 63.2461C136.341 63.8986 137.292 64.2112 138.502 64.1976C139.807 64.1976 140.772 63.8986 141.411 63.3413C142.05 62.784 142.362 62.1315 142.349 61.3975C142.349 60.6771 142.036 60.1334 141.452 59.7392C140.854 59.345 140.12 59.0052 139.223 58.7062C138.339 58.4072 137.36 58.1081 136.314 57.8227C135.267 57.5372 134.288 57.143 133.405 56.6265C132.508 56.11 131.774 55.4576 131.176 54.6284C130.578 53.8128 130.279 52.7254 130.265 51.3662L130.279 51.3934Z"
        fill="white"
      />
      <path
        d="M165.09 61.8733C164.886 62.716 164.546 63.518 164.07 64.2928C163.594 65.0676 163.01 65.7472 162.317 66.3453C161.623 66.9434 160.835 67.4191 159.938 67.7725C159.054 68.1259 158.103 68.289 157.084 68.289C155.928 68.289 154.841 68.0444 153.821 67.5822C152.802 67.1201 151.905 66.4948 151.13 65.6928C150.355 64.9045 149.743 63.9802 149.295 62.9199C148.846 61.8733 148.629 60.7451 148.629 59.5354C148.629 58.3256 148.874 57.1974 149.336 56.1644C149.798 55.1178 150.423 54.2071 151.198 53.4187C151.986 52.6303 152.883 52.0187 153.903 51.5701C154.922 51.1215 156.023 50.9041 157.179 50.9041C158.185 50.9041 159.136 51.0943 160.02 51.4614C160.903 51.8284 161.691 52.3177 162.371 52.9158C163.051 53.5274 163.635 54.2207 164.097 54.9954C164.559 55.7702 164.886 56.5858 165.076 57.4285L160.821 57.4013C160.631 56.9528 160.359 56.5586 160.02 56.2188C159.72 55.9197 159.34 55.6343 158.878 55.376C158.416 55.1178 157.831 54.9818 157.138 54.9818C155.928 54.9818 154.922 55.3896 154.093 56.2188C153.278 57.0479 152.856 58.1625 152.843 59.5762C152.843 60.9762 153.237 62.1044 154.039 62.9471C154.841 63.7899 155.847 64.2112 157.056 64.2248C157.75 64.2248 158.32 64.1161 158.796 63.8714C159.272 63.6268 159.653 63.3549 159.952 63.0695C160.305 62.7296 160.577 62.3219 160.767 61.8597L165.022 61.8869L165.09 61.8733Z"
        fill="white"
      />
      <path
        d="M171.633 68.3026L167.379 68.2211L167.692 51.2167L171.946 51.2982L171.633 68.3026ZM169.961 49.0419C169.323 49.0419 168.765 48.7836 168.29 48.2943C167.814 47.8049 167.583 47.234 167.596 46.5952C167.596 45.9563 167.855 45.399 168.344 44.9233C168.833 44.4475 169.404 44.2165 170.043 44.2301C170.682 44.2437 171.239 44.4883 171.715 44.9777C172.191 45.467 172.422 46.0379 172.408 46.6767C172.408 47.3156 172.15 47.8729 171.661 48.3486C171.171 48.8244 170.6 49.0554 169.961 49.0419Z"
        fill="white"
      />
      <path
        d="M190.388 63.654C190.143 64.2929 189.79 64.9181 189.314 65.5434C188.838 66.1686 188.268 66.7259 187.601 67.2153C186.935 67.7046 186.188 68.0852 185.345 68.3842C184.516 68.6833 183.619 68.8056 182.654 68.7784C181.498 68.7376 180.411 68.4794 179.405 67.9901C178.399 67.5007 177.516 66.8483 176.768 66.0463C176.021 65.2307 175.422 64.2929 175.001 63.2326C174.58 62.1724 174.389 61.0442 174.417 59.8345C174.457 58.6247 174.716 57.5101 175.205 56.4771C175.694 55.4441 176.333 54.5469 177.135 53.7858C177.937 53.0246 178.848 52.4265 179.894 52.0051C180.927 51.5837 182.028 51.3935 183.184 51.4206C184.298 51.4614 185.331 51.6925 186.31 52.141C187.289 52.5896 188.132 53.2149 188.838 53.9896C189.559 54.778 190.116 55.7159 190.51 56.8033C190.904 57.8907 191.081 59.0733 191.054 60.351C191.054 60.5549 191.027 60.7452 191 60.9219C190.972 61.1122 190.945 61.2889 190.904 61.452C190.877 61.6423 190.837 61.8326 190.809 62.0093L178.984 61.6423C179.16 62.485 179.555 63.2054 180.18 63.7899C180.805 64.3744 181.675 64.687 182.776 64.7278C183.347 64.7414 183.823 64.7006 184.203 64.5919C184.584 64.4832 184.924 64.3472 185.196 64.1841C185.495 63.9938 185.753 63.7763 185.957 63.5453L190.388 63.6812V63.654ZM183.062 55.4712C181.947 55.4305 181.036 55.7295 180.316 56.3412C179.595 56.9664 179.12 57.7276 178.889 58.6655L187.017 58.9238C186.854 57.9859 186.419 57.1839 185.739 56.5179C185.06 55.8518 184.163 55.512 183.048 55.4712H183.062Z"
        fill="white"
      />
      <path
        d="M202.8 52.2225C203.806 52.2769 204.73 52.4808 205.546 52.8614C206.361 53.242 207.054 53.7585 207.625 54.411C208.196 55.0634 208.631 55.8518 208.917 56.7489C209.202 57.646 209.324 58.6519 209.256 59.7664L208.753 69.7298L204.513 69.5124L204.988 60.0927C205.043 58.9917 204.757 58.081 204.146 57.3605C203.534 56.6537 202.705 56.2731 201.672 56.2188C200.639 56.1644 199.782 56.4634 199.089 57.1023C198.396 57.7411 198.029 58.6247 197.974 59.7257L197.499 69.1454L193.258 68.9279L194.128 51.9507L197.268 52.1138L197.92 53.8129C198.355 53.4866 198.831 53.2012 199.334 52.9565C199.769 52.7527 200.272 52.5759 200.856 52.3992C201.441 52.2361 202.093 52.1682 202.813 52.2089L202.8 52.2225Z"
        fill="white"
      />
      <path
        d="M227.887 64.8229C227.629 65.6384 227.235 66.4268 226.704 67.1608C226.174 67.8948 225.549 68.5472 224.815 69.091C224.081 69.6482 223.266 70.056 222.355 70.3551C221.444 70.6405 220.479 70.7492 219.473 70.6813C218.318 70.5997 217.244 70.2871 216.265 69.757C215.273 69.2269 214.43 68.5337 213.71 67.6909C212.99 66.8482 212.446 65.8831 212.079 64.8093C211.698 63.7355 211.562 62.5937 211.644 61.3839C211.739 60.1742 212.052 59.0732 212.582 58.0673C213.112 57.0615 213.792 56.1915 214.634 55.4575C215.463 54.7235 216.415 54.1798 217.461 53.7992C218.508 53.4186 219.623 53.2691 220.778 53.3643C221.784 53.4458 222.722 53.6905 223.578 54.1119C224.435 54.5332 225.182 55.0769 225.835 55.7294C226.473 56.3818 227.004 57.1158 227.411 57.9178C227.819 58.7198 228.091 59.5625 228.227 60.4053L223.986 60.0926C223.823 59.6305 223.578 59.2227 223.266 58.8557C222.994 58.5431 222.627 58.2304 222.192 57.945C221.743 57.646 221.172 57.4828 220.493 57.4285C219.283 57.3333 218.25 57.6867 217.38 58.4615C216.51 59.2363 216.021 60.3237 215.912 61.7237C215.803 63.1238 216.129 64.2792 216.877 65.1627C217.625 66.0598 218.603 66.5491 219.799 66.6443C220.493 66.6986 221.077 66.6171 221.553 66.3996C222.042 66.1957 222.436 65.9511 222.763 65.6792C223.13 65.3666 223.429 64.9724 223.66 64.5238L227.901 64.8365L227.887 64.8229Z"
        fill="white"
      />
      <path
        d="M245.233 67.7453C244.947 68.357 244.54 68.9551 244.023 69.5532C243.506 70.1376 242.895 70.6542 242.188 71.0891C241.481 71.5241 240.706 71.8503 239.85 72.0814C238.994 72.3125 238.097 72.3804 237.132 72.2717C235.976 72.1493 234.916 71.8095 233.951 71.2522C232.986 70.6949 232.157 69.9745 231.463 69.1046C230.77 68.2347 230.254 67.256 229.914 66.1686C229.574 65.0812 229.465 63.9394 229.588 62.7297C229.71 61.5335 230.063 60.4325 230.621 59.4402C231.178 58.448 231.898 57.6052 232.755 56.8984C233.611 56.1916 234.576 55.6751 235.636 55.3217C236.697 54.9819 237.811 54.8595 238.967 54.9819C240.068 55.1042 241.087 55.4168 242.025 55.9333C242.963 56.4499 243.751 57.1295 244.404 57.9722C245.056 58.815 245.545 59.7801 245.858 60.8947C246.171 62.0092 246.266 63.2054 246.13 64.4695C246.103 64.6598 246.075 64.8501 246.035 65.0404C245.994 65.2171 245.953 65.3938 245.899 65.5705C245.858 65.7608 245.804 65.9511 245.763 66.1142L234.005 64.8637C234.114 65.72 234.454 66.454 235.038 67.0929C235.609 67.7182 236.452 68.0987 237.553 68.2211C238.11 68.2755 238.6 68.2755 238.994 68.1939C239.388 68.1123 239.728 68.0036 240.013 67.8541C240.326 67.691 240.598 67.5007 240.815 67.2696L245.233 67.7317V67.7453ZM238.532 59.0461C237.431 58.9237 236.493 59.1548 235.731 59.7121C234.97 60.2694 234.44 61.017 234.141 61.9277L242.229 62.784C242.134 61.8325 241.767 61.0034 241.141 60.283C240.516 59.5762 239.646 59.1548 238.545 59.0461H238.532Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_532_3058"
          x1="144.71"
          y1="236.24"
          x2="141.32"
          y2="5.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4992C" />
          <stop offset="0.42" stopColor="#F2B12F" />
          <stop offset="1" stopColor="#F4992C" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_532_3058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(156.719 120.52) scale(86.78 86.78)"
        >
          <stop stopColor="#5B3303" stopOpacity="0" />
          <stop offset="0.62" stopColor="#5B3303" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_532_3058"
          x1="15.9997"
          y1="59.9702"
          x2="269.26"
          y2="59.9702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32BE8D" />
          <stop offset="1" stopColor="#2D8B77" />
        </linearGradient>
      </defs>
      <text
        x="50%"
        y={level < 1000 ? '74%' : '72%'}
        fill="#fff"
        fontFamily="DM Sans, sans-serif"
        fontSize={level < 1000 ? '4.5rem' : '3.5rem'}
        fontWeight={500}
        textAnchor="middle"
      >
        {level}
      </text>
    </svg>
  );
};
