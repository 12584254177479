import { Box, Button, Flex, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { faArrowRight, faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task, TaskItem, TaskItem_State } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { XpAward, XpAward_Reason } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { isComplete } from '@sparx/packageactivity';
import { ProgressBar } from '@sparx/sparx-design/components';
import { useUpdateUserXpState } from 'api/xp';
import { Hide, useMediaQuery } from 'components/chakraExports';
import { FlashCardCounts, useFlashCardCounts } from 'components/rapidfire/hooks';
import { SmallFlashcard } from 'components/rapidfire/SmallFlashcard';
import { LevelBadge } from 'components/xp/Badges/Badge';
import lightningIcon from 'components/xp/images/lightning_no_outline.svg';
import { getLevelProgress } from 'components/xp/utils';
import { useXpContext } from 'components/xp/XpManager/context';
import { AnimatePresence, motion } from 'motion/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { useKeyPress } from 'utils/hooks/keypress';
import { isTaskRapidFire } from 'utils/rapidfire';

interface ResultsProps {
  task: Task | undefined;
  onContinue?: () => void;
  onFinish?: () => void;
}

export const Results = ({ task, onContinue, onFinish }: ResultsProps) => {
  const complete = isComplete(task?.state?.completion);
  const completion = task?.state?.completion;
  const completeItems = (completion?.progress?.['C'] || 0) + (completion?.progress?.['SK'] || 0);
  const cftItems = completion?.progress?.['CFT'] || 0;
  const totalItems = completion?.size || 0;

  // get any xp rewards from the task
  const { getAwardsToShow, markAwardsAsShown, showLevelUp } = useXpContext();
  const { awards } = useMemo(() => {
    const awards = getAwardsToShow(XpAward_Reason.TASK_COMPLETE);
    const ilTargetCompleted = awards.some(a =>
      a.additionalData.some(
        d =>
          d.data.oneofKind === 'ilTargetStateUpdate' &&
          !!d.data.ilTargetStateUpdate.completedTimestamp,
      ),
    );

    return { awards, ilTargetCompleted };
  }, [getAwardsToShow]);

  const showAwards = awards.length > 0;

  // update the userXpState query to include the most recent xpState from the awards
  const updateUserXpState = useUpdateUserXpState();
  useEffect(() => {
    updateUserXpState(awards);
  }, [awards, updateUserXpState]);

  useKeyPress({ Enter: () => (!complete ? onContinue?.() : onFinish?.()) });

  const isRapidFire = isTaskRapidFire(task);

  let content = null;
  let button = null;
  if (!complete) {
    content = (
      <>
        <Heading size="lg">Keep going!</Heading>
        <Text color="gray.500" fontWeight="bold">
          {completeItems}/{totalItems} complete
        </Text>
      </>
    );
    button = (
      <Button
        size={['sm', 'md']}
        colorScheme="buttonTeal"
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={onContinue}
      >
        Keep going
      </Button>
    );
  } else {
    content = (
      <>
        <Hide breakpoint={`(max-height: ${showAwards ? 700 : 500}px)`}>
          <Box
            width={100}
            height={100}
            color="green.500"
            fontSize="5xl"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background="gray.100"
            borderRadius="full"
            mb={4}
            flex="0 0 auto"
          >
            <FontAwesomeIcon icon={faCheck} />
          </Box>
        </Hide>
        <Heading size="lg">
          <Text color="green.500" as="span">
            Task complete!
          </Text>{' '}
          Well done.
        </Heading>
        {!isRapidFire ? (
          <Text color="gray.500" fontWeight="bold">
            You got {cftItems}/{totalItems} correct first time.
          </Text>
        ) : (
          <RapidFireResults task={task} taskItem={task?.contents?.taskItems[0]} />
        )}
        {showAwards && <XpCard animate={!showLevelUp} xpAwards={awards} />}
      </>
    );
    button = (
      <Button
        size={['sm', 'md']}
        colorScheme="buttonTeal"
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={() => {
          onFinish?.();
          markAwardsAsShown(XpAward_Reason.TASK_COMPLETE);
        }}
        {...(isRapidFire
          ? {
              bg: 'linear-gradient(90deg, #E97B2A 0%, #D34A59 100%)',
              _hover: { bg: 'linear-gradient(90deg, #D34A59 0%, #E97B2A 100%)' },
              mb: 6,
            }
          : {})}
      >
        Finish
      </Button>
    );
  }

  const [smallHeight] = useMediaQuery('(max-height: 800px)');
  return (
    <VStack h="100%">
      <VStack spacing={2} overflow="auto" flex="1 1 auto" py={smallHeight ? 4 : 14} w="100%">
        {content}
      </VStack>
      <HStack flex="0 0 auto" w="100%" p={3} justifyContent={isRapidFire ? 'center' : 'flex-end'}>
        {button}
      </HStack>
    </VStack>
  );
};

const XpCard = ({ xpAwards, animate }: { xpAwards: XpAward[]; animate: boolean }) => {
  const xpEarned = xpAwards.reduce((acc, award) => acc + award.xpAwarded, 0);
  const ilTargetCompleted = xpAwards.some(a =>
    a.additionalData.some(
      d =>
        d.data.oneofKind === 'ilTargetStateUpdate' &&
        !!d.data.ilTargetStateUpdate.completedTimestamp,
    ),
  );
  // last award is the most recent
  const xpState = xpAwards[xpAwards.length - 1];
  const { level, levelCompletion, xpToNext } = getLevelProgress(
    xpState.xpStateAfterAward?.currentXp || 0,
  );
  // if the level completion is 0, we are at a level exactly, so show it as full rather than empty
  const lvlComp = levelCompletion || 1;
  return (
    <Box maxW={325} my={5} w="100%">
      <Flex backgroundColor="gray.100" p={5} borderRadius={'lg'} flexDir="column">
        <Heading color="green.500" fontWeight="bold" size="lg" textAlign="center">
          You earned +{xpEarned} XP!
        </Heading>
        <HStack pt={5}>
          <ProgressBar animateFill={animate} showHighlight percentComplete={lvlComp * 100} />
          <LevelBadge
            level={lvlComp < 1 ? level + 1 : level}
            width={'60px'}
            filter={lvlComp < 1 ? 'grayscale(1)' : ''}
            opacity={lvlComp < 1 ? 0.3 : 1}
          />
        </HStack>
        {xpToNext <= 100 && (
          <Text pt={5} color={'blue.800'} textAlign="center" fontWeight={'bold'} fontSize={'lg'}>
            {xpToNext} XP more to reach Level {level + 1}
          </Text>
        )}
      </Flex>
      {ilTargetCompleted && (
        <AnimatePresence initial={animate}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.4,
              delay: 0.3,
              ease: [0, 0.71, 0.2, 1.5],
            }}
          >
            <Flex
              backgroundColor="gray.100"
              p={5}
              borderRadius={'lg'}
              mt={3}
              justifyContent="space-between"
              bgColor={'#FBEAB2'}
            >
              <Text>
                <Text as="span" color="yellow.600" fontWeight="bold">
                  Well done!
                </Text>{' '}
                You completed your Independent Learning Challenge for this week
              </Text>
              <Image src={lightningIcon} ml={2} w={30} />
            </Flex>
          </motion.div>
        </AnimatePresence>
      )}
    </Box>
  );
};

const RapidFireResults = ({ task, taskItem }: { task?: Task; taskItem?: TaskItem }) => {
  // const { data: activities, isSuccess } = useTaskItemActivities(taskItem?.name || '');
  const counts = useFlashCardCounts(task);

  if (!taskItem || !taskItem.state || !counts) {
    return null;
  }

  return <RapidFireResultsInner counts={counts} taskItemState={taskItem.state} />;
};

const RapidFireResultsInner = ({
  counts,
  taskItemState,
}: {
  counts: FlashCardCounts;
  taskItemState: TaskItem_State;
}) => {
  let accuracy =
    (taskItemState.correctActivities * 100) /
    (taskItemState.incorrectActivities + taskItemState.correctActivities);

  // Round correct up or down to nearest integer if it's close to 0 or 100
  if (accuracy > 0 && accuracy < 1) accuracy = Math.ceil(accuracy);
  else if (accuracy > 99 && accuracy < 100) accuracy = Math.floor(accuracy);
  else accuracy = Math.round(accuracy);

  const animationDuration = 1;
  const delay = 0.5;
  const countDelay = delay + 0.5;

  const [newCompleted, setNewCompleted] = React.useState(false);
  const [reviewCompleted, setReviewCompleted] = React.useState(false);

  const newRef = useRef<HTMLParagraphElement>(null);
  const { start: startNew } = useCountUp({
    ref: newRef,
    start: counts.newCards,
    end: 0,
    duration: animationDuration,
    delay: countDelay,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
    onEnd: () => setNewCompleted(true),
  });
  const reviewRef = useRef<HTMLParagraphElement>(null);
  const { start: startReview } = useCountUp({
    ref: reviewRef,
    start: counts.reviewCards,
    end: 0,
    duration: animationDuration,
    delay: countDelay,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
    onEnd: () => setReviewCompleted(true),
  });
  const learningRef = useRef<HTMLParagraphElement>(null);
  const { start: startLearning } = useCountUp({
    ref: learningRef,
    start: 0,
    end: counts.learning,
    duration: animationDuration,
    delay: countDelay,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
  });
  const learntRef = useRef<HTMLParagraphElement>(null);
  const { start: startLearnt } = useCountUp({
    ref: learntRef,
    start: 0,
    end: counts.learnt,
    duration: animationDuration,
    delay: countDelay,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
  });

  useEffect(() => {
    startNew();
    startReview();
    startLearning();
    startLearnt();
  }, [startNew, startReview, startLearning, startLearnt]);

  return (
    <>
      <Text color="gray.500" fontWeight="bold">
        You were {accuracy}% accurate!
      </Text>
      <HStack spacing={14} wrap={'wrap'} justifyContent="center" mt={6}>
        <HStack spacing={8}>
          <SmallFlashcard
            label="New"
            bg="linear-gradient(0deg, #2D4E8A 0%, #4570BF 100%)"
            ref={newRef}
            gray={newCompleted}
          >
            {counts.newCards}
          </SmallFlashcard>
          <SmallFlashcard
            label="Review"
            bg="linear-gradient(335.07deg, #0C7C7B 3.62%, #1CC2C1 103.49%)"
            ref={reviewRef}
            gray={reviewCompleted}
          >
            {counts.reviewCards}
          </SmallFlashcard>
        </HStack>
        <Box color="gray.300">
          <FontAwesomeIcon icon={faArrowRight} size="xl" />
        </Box>
        <HStack spacing={8}>
          <SmallFlashcard
            label="Learning"
            bg="linear-gradient(341.2deg, #DE6342 12.52%, #FCA502 93.37%)"
            ref={learningRef}
          >
            0
          </SmallFlashcard>
          <SmallFlashcard
            label="Learnt"
            bg="linear-gradient(165.45deg, #54D88B 14.22%, #269A59 108.7%)"
            ref={learntRef}
          >
            0
          </SmallFlashcard>
        </HStack>
      </HStack>
    </>
  );
};
