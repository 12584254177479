import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import {
  faArrowUpRightFromSquare,
  faPersonChalkboard,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { FocusSkill } from '@sparx/api/apis/sparx/science/packages/v1/insights';
import { useLayoutSteps } from '@sparx/question';
import { useTopicLookup } from 'api/content';
import { useAssignmentInsights } from 'api/packages';
import { useClientEvent } from 'components/ClientEventProvider';
import { NoHomework } from 'components/errorpages/NoHomework';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SparxScienceQuestion } from 'components/question/SparxScienceQuestion';
import { WeekSelector } from 'components/weekselector/WeekSelector';
import queryString from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import { findSubject, subjects } from 'utils/subjects';
import { useAssignmentLinker } from 'views/handin/HandInView';

export const InsightsView = () => (
  <React.Suspense fallback={<LargeLoading />}>
    <SuspenseInsightsView />
  </React.Suspense>
);

const SuspenseInsightsView = () => {
  const { assignmentLinker, group, assignments, foundAssignment, assignmentID, groupID } =
    useAssignmentLinker(`/teacher/insights`);

  const pageTitle = group ? `${group.displayName} Insights` : undefined;

  const { sendEvent } = useClientEvent();
  const sendInsightsEvent = (action: string, labels?: Dictionary<string, string>) =>
    sendEvent({ category: 'insights', action }, { assignmentID, groupID, ...labels });

  const [tabIndex, setTabIndex] = React.useState(0);
  const subject = subjects[tabIndex].key;

  if (!group) {
    return null;
  }
  if (assignments.length === 0) {
    return <NoHomework title={pageTitle} groupID={groupID} />;
  }

  return (
    <PageContainer>
      <PageTitle title="Insights" pageTitleOverride={pageTitle} />
      <WeekSelector
        assignments={assignments}
        selected={foundAssignment?.name}
        assignmentLink={a =>
          assignmentLinker({
            assignment: a.replace('assignments/', ''),
          })
        }
      />
      <Tabs
        index={tabIndex}
        onChange={idx => {
          setTabIndex(idx);
          sendInsightsEvent('tab_change', { subject: subjects[idx].key });
        }}
        mt={5}
        variant="enclosed"
        isFitted
        colorScheme="buttonTeal"
      >
        <TabList as={HStack} spacing={2} borderBottomWidth={2}>
          {subjects.map(({ name, key, icon }) => (
            <Tab
              key={key}
              fontWeight="bold"
              bg="white"
              _selected={{ bg: 'blue.800', color: 'white' }}
            >
              <HStack spacing={2}>
                {icon && <Text opacity={0.7}>{icon}</Text>}
                <Text>{name}</Text>
              </HStack>
            </Tab>
          ))}
        </TabList>
      </Tabs>

      {foundAssignment?.name && (
        <React.Suspense fallback={<LargeLoading />}>
          <FollowUpFive
            assignmentID={assignmentID}
            groupID={groupID}
            key={assignmentID}
            subject={subject}
          />
        </React.Suspense>
      )}
    </PageContainer>
  );
};

interface FollowUpFiveProps {
  assignmentID: string;
  groupID: string;
  subject: string;
}

const FollowUpFive = ({ assignmentID, groupID, subject }: FollowUpFiveProps) => {
  const { data } = useAssignmentInsights(assignmentID, subject, { suspense: true });
  const { data: topicLookup } = useTopicLookup({ suspense: true });
  const { sendEvent } = useClientEvent();

  const sendInsightsEvent = (action: string, labels?: Dictionary<string, string>) =>
    sendEvent({ category: 'insights', action }, { assignmentID, groupID, subject, ...labels });

  const displayLink = `/teacher/insights/display?${queryString.stringify({
    assignment: assignmentID,
    group: groupID,
    subject,
  })}`;
  const printLink = `/teacher/insights/print?${queryString.stringify({
    assignment: assignmentID,
    group: groupID,
    subject,
  })}`;
  const noSkills = (data?.skills?.length || 0) === 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="md"
      boxShadow="elevationLow"
      overflow="hidden"
      mt={4}
    >
      <Box bg="blue.800" color="white" p={2} fontWeight="bold" display="flex" alignItems="center">
        <Text ml={3}>Follow-up five</Text>
        <Spacer />
        <Button
          bg="white"
          color="teal.800"
          leftIcon={<FontAwesomeIcon icon={faPrint} />}
          rightIcon={
            <Text opacity={0.75} ml={2}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Text>
          }
          as={Link}
          to={printLink}
          target="_blank"
          pointerEvents={!noSkills ? 'auto' : 'none'}
          isDisabled={noSkills}
          onClick={() => sendInsightsEvent('print_view')}
          mr={2}
        >
          Print
        </Button>
        <Button
          bg="white"
          color="teal.800"
          leftIcon={<FontAwesomeIcon icon={faPersonChalkboard} />}
          rightIcon={
            <Text opacity={0.75} ml={2}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Text>
          }
          as={Link}
          to={displayLink}
          target="_blank"
          pointerEvents={!noSkills ? 'auto' : 'none'}
          isDisabled={noSkills}
          onClick={() => sendInsightsEvent('presentation_view')}
        >
          Presentation view
        </Button>
      </Box>
      <Box bg="white" p={3}>
        <Accordion
          allowMultiple
          onChange={idx => sendInsightsEvent('toggle_skills', { open: idx.toString() })}
        >
          {noSkills && <NoFollowUpFive />}
          {data?.skills?.map((skill, i) => (
            <SkillPanel
              skill={skill}
              topic={topicLookup?.[skill.topicName]?.topic}
              key={i}
              index={i + 1}
            />
          ))}
        </Accordion>
      </Box>
    </Box>
  );
};

export const NoFollowUpFive = () => (
  <Text fontWeight="bold" color="orange.600" p={2}>
    No follow-up five has been generated for this subject yet. This could be because there are no
    questions for this subject in this homework, or there are not enough attempts where students
    struggled.
  </Text>
);

interface SkillPanelProps {
  skill: FocusSkill;
  topic?: Topic;
  index: number;
}

const SkillPanel = ({ skill, topic, index }: SkillPanelProps) => {
  const steps = useLayoutSteps(skill.questions[0]?.layoutJson);

  const sub = findSubject(skill.subject);

  return (
    <AccordionItem
      bg="gray.50"
      borderLeft="1px"
      borderRight="1px"
      borderLeftColor="gray.200"
      borderRightColor="gray.200"
    >
      <AccordionButton px={3} py={3} justifyContent="space-between">
        <Flex>
          <AccordionIcon mr={2} />
          <Text color="blue.800" fontWeight="bold">
            <Text as="span" width={6} display="inline-block">
              {index}.
            </Text>{' '}
            {topic?.displayName || '?'}
          </Text>
        </Flex>
        {sub?.icon}
      </AccordionButton>
      <AccordionPanel>
        <Box bg="white" px={8} py={7} borderRadius="md" borderWidth="1px" borderColor="gray.200">
          {steps.length > 0 && steps[0] ? (
            <SparxScienceQuestion
              layout={steps[0].layout}
              input={steps[0].input}
              setInput={() => undefined}
              readOnly={true}
              insightsMode="presentation"
              shuffleSeed="noshuffle"
            />
          ) : (
            <>Error loading question</>
          )}
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};
