import { Text, VStack } from '@chakra-ui/react';
import { LessonState, ListLessonPageToken } from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useQuery } from '@tanstack/react-query';
import { getSchoolID } from 'api/sessions';
import { Beta } from 'components/beta/Beta';
import { LargeLoading } from 'components/loading/LargeLoading';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { AnimatePresence } from 'motion/react';
import React from 'react';
import { LessonRow } from 'views/lessons/components/LessonRow';

import { usePagedLessonList } from './usePagedLessonList';

const isSparxTeaching = import.meta.env.VITE_SPARX_PRODUCT === 'SPARX_TEACHING';

interface LessonListPageProps {
  title: string;
  noLessonsMessage?: string;
  path: string;
  backButton?: string;
  titleComponent?: React.ReactNode;
  query: (args: {
    pageToken: ListLessonPageToken;
    groupNames: string[];
    schoolName: string;
    teamTeachingLessons: boolean;
  }) => Promise<{
    lessons: LessonState[];
    nextPageToken?: ListLessonPageToken;
  }>;
}

export const LessonListPage = ({
  title,
  noLessonsMessage,
  backButton,
  path,
  titleComponent,
  query,
}: LessonListPageProps) => {
  const { group, pageToken, getNavigation } = usePagedLessonList(path);
  const groupQuery = group ? `?group=${group.name.split('/')[3]}` : '';

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'lessons',
      {
        path,
        group: group?.name,
        token: pageToken,
      },
    ],
    queryFn: async () =>
      query({
        schoolName: `schools/${await getSchoolID()}`,
        groupNames: group ? [group.name] : [],
        teamTeachingLessons: isSparxTeaching,
        pageToken: pageToken,
      }),
    staleTime: 0,
  });

  return (
    <PageContainer>
      <PageTitle
        pageTitleOverride={group ? `${group.displayName} ${title}` : title}
        backButton={backButton ? `${backButton}${groupQuery}` : undefined}
        title={
          <>
            {title} <Beta ml={4} mt={0.5} />
          </>
        }
      >
        {titleComponent}
      </PageTitle>
      {isLoading && <LargeLoading />}
      {isError && <Text>Error loading lessons.</Text>}
      {data && (
        <>
          <LessonList key={data.nextPageToken?.page} lessons={data.lessons} />
          {(!data.lessons || data.lessons?.length === 0) && (
            <Text>
              {noLessonsMessage || 'No lessons'}
              {group && ` for ${group.displayName}`}.
            </Text>
          )}
          {getNavigation(data.nextPageToken)}
        </>
      )}
    </PageContainer>
  );
};

const LessonList = ({ lessons }: { lessons?: LessonState[] }) => {
  return (
    <VStack spacing={4} align="stretch">
      <AnimatePresence initial={false}>
        {lessons?.map(lesson => <LessonRow lesson={lesson} key={lesson.lessonName} />)}
      </AnimatePresence>
    </VStack>
  );
};
