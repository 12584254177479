import {
  RolloverState_ProductRolloverState_RolloverType,
  SchoolYear,
} from '@sparx/api/apis/sparx/school/calendar/v4/calendar';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useAssignments } from 'api/planner';
import { SchoolYearWithDates, useSchoolYears, useScienceEnabledDate } from 'api/school';
import {
  useIsInRolloverInterim,
  useMostRecentRolloverWasManual,
  useSchoolCalendar,
} from 'api/schoolcalendar';
import { add, isFuture, isPast, sub } from 'date-fns';

import { gettingStartedSteps } from './GettingStartedSteps';
import { newYearSetupSteps } from './NewYearSetup';

interface Progress {
  total: number;
  complete: number;
  loading: boolean;
}

export const useGettingStartedProgress = () =>
  gettingStartedSteps.reduce<Progress>(
    (acc, step) => {
      const status = step.detailsHook({ isRollover: false }).status;
      acc.total++;

      if (status === 'loading') {
        acc.loading = true;
      } else if (status === 'complete') {
        acc.complete++;
      }

      return acc;
    },
    {
      total: 0,
      complete: 0,
      loading: false,
    },
  );

const maxGettingStartedMessagingDays = 60;
export const useShowGettingStartedMessaging = () => {
  const dateEnabled = useScienceEnabledDate();
  const isNew = isFuture(add(dateEnabled, { days: maxGettingStartedMessagingDays }));
  const { total, complete, loading } = useGettingStartedProgress();

  const manualRollover = useMostRecentRolloverWasManual();
  const { data: assignments, isSuccess } = useAssignments({ suspense: false });

  // If the school has manually rolled over then we shouldn't show the getting started steps.
  // Or if we've not finished loading the data we need.
  if (manualRollover || !isSuccess || loading) {
    return false;
  }

  const hasSetHomework = assignments?.some(a => !!a.generatedTimestamp);
  // If all steps have been completed and homework has been set, don't show.
  if (complete >= total && hasSetHomework) {
    return false;
  }

  // If the school has set homework and its over 60 days since they started, don't show.
  if (!isNew && hasSetHomework) {
    return false;
  }

  return true;
};

const maxNewYearSetupMessagingDays = 60;
export type NewYearSetupDisplayState = 'before' | 'interim' | 'reset' | 'reset-complete';
export const useShowNewYearSetupMessaging = (): NewYearSetupDisplayState | false => {
  const { isCurrentYear, yearV4 } = useYearToReference();
  const inInterim = useIsInRolloverInterim();
  const { data: assignments, isSuccess } = useAssignments({ suspense: false, enabled: !inInterim });

  const { total, complete, loading: loadingCompletion } = useNewYearSetupProgress();

  if (inInterim) {
    // If the school is in the interim period always show.
    return 'interim';
  }

  if (!isSuccess) {
    // Wait until we've loaded the assignments
    return false;
  }

  const hasSetHomework = assignments?.some(a => !!a.generatedTimestamp);
  if (!isCurrentYear) {
    // We're before rollover, show the before messaging if we've set homework, otherwise show no messaging.
    return hasSetHomework && 'before';
  }

  // If there's no rollover state we didn't roll into this year, so nothing to show.
  // Or if we're still loading the completion status.
  const rolloverState = yearV4?.rolloverState?.scienceRolloverState;
  if (
    rolloverState?.rolloverType !== RolloverState_ProductRolloverState_RolloverType.PARTIAL ||
    !rolloverState?.rolloverCompleteTime ||
    loadingCompletion
  ) {
    return false;
  }

  const rolloverDate = Timestamp.toDate(rolloverState.rolloverCompleteTime!);
  const isRecent = isFuture(add(rolloverDate, { days: maxNewYearSetupMessagingDays }));
  // If all steps are complete and homework has been set, don't show.
  if (complete >= total && hasSetHomework) {
    return false;
  }

  // If it's been 60 days since reset, and homework has been set, don't show.
  if (!isRecent && hasSetHomework) {
    return false;
  }

  // We've rolled over and reset, show the messaging.
  return complete >= total ? 'reset-complete' : 'reset';
};

export interface YearToReference {
  isCurrentYear?: boolean;
  yearV3?: SchoolYearWithDates;
  yearV4?: SchoolYear;
}

export const useYearToReference = (): YearToReference => {
  const { data: schoolYears = [] } = useSchoolYears({ suspense: true }, true);

  const { data: cal } = useSchoolCalendar({ suspense: true });

  const currentYear = schoolYears.find(sy => sy.current);
  const nextYear = schoolYears.find(sy => sy.next);

  if (!cal || !currentYear || !nextYear) {
    // This shouldn't be possible
    return {};
  }

  if (isPast(sub(currentYear.end, { weeks: 8 }))) {
    return { yearV3: nextYear, yearV4: cal.pendingYear };
  }
  return { isCurrentYear: true, yearV3: currentYear, yearV4: cal.currentYear };
};

export const useNewYearSetupProgress = () =>
  newYearSetupSteps.reduce<Progress>(
    (acc, step) => {
      const status = step.detailsHook({ isRollover: true }).status;
      acc.total++;

      if (status === 'loading') {
        acc.loading = true;
      } else if (status === 'complete') {
        acc.complete++;
      }

      return acc;
    },
    {
      total: 0,
      complete: 0,
      loading: false,
    },
  );

export const useIsManualRolloverStepComplete = (annotation: string) => {
  const { yearV4 } = useYearToReference();
  return yearV4?.rolloverState?.scienceRolloverState?.annotations?.[annotation] === 'true';
};
