import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { loadProductUrls, ProductWithUrl } from '@sparx/api/utils/products';
import { productIcons } from '@sparx/sparx-design/icons/ProductIcons';
import { useQuery } from '@tanstack/react-query';
import { indexweaverApiURL, requestAccessToken } from 'api';
import { useSession, useUserType } from 'api/sessions';
import { useClientEvent } from 'components/ClientEventProvider';
import { SimpleAlert } from 'components/simplealert/SimpleAlert';
import { motion } from 'motion/react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

// List of paths that should not prompt the user whether they want to switch app
const noPromptPaths = ['', '/packages', '/independentlearning', '/assessments'];

export const ProductSwitch = () => {
  const { data: user } = useSession();
  const { isTeacher } = useUserType();
  const location = useLocation();

  // Send page event with immediate: true to ensure it is sent immediately
  const { sendEvent: sendClientEvent } = useClientEvent();
  const sendEvent = (
    action: 'switched product' | 'prompt switch',
    product: string,
    labels?: Record<string, string>,
  ) =>
    sendClientEvent(
      { action, category: 'product switch' },
      { product, ...labels },
      { immediate: true },
    );

  const { data } = useQuery({
    queryKey: ['school', 'products'],
    queryFn: async () =>
      loadProductUrls({
        indexweaverApiURL,
        schoolID: user?.schoolId || '',
        isTeacher,
        authorizationToken: await requestAccessToken(),
        product: 'SPARX_SCIENCE',
      }),
    enabled: Boolean(user?.schoolId),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const [selectedProduct, setSelectedProduct] = useState<ProductWithUrl | undefined>();
  const clickProduct = (product: ProductWithUrl, openModal: () => void) => {
    // Trim any trailing slashes
    const pathname = location.pathname.replace(/\/$/, '');
    if (noPromptPaths.includes(pathname)) {
      // Redirect instantly
      sendEvent('switched product', product.key, { confirmed: 'no' });
      window.location.href = product.url;
    } else {
      // Open modal to prompt the user before redirecting
      sendEvent('prompt switch', product.key);
      setSelectedProduct(product);
      openModal();
    }
  };

  const gotoSelectedProduct = async () => {
    if (selectedProduct) {
      sendEvent('switched product', selectedProduct.key, { confirmed: 'yes' });
      window.location.href = selectedProduct.url;
    }
  };

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <SimpleAlert
      header={`Switch to ${selectedProduct?.name || 'another app'}`}
      body={`Are you sure you want to switch to ${selectedProduct?.name || 'another app'}?`}
      onConfirm={gotoSelectedProduct}
      confirmText="Switch"
    >
      {onOpen => (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          style={{ overflow: 'hidden' }}
        >
          <MenuGroup title="Switch to:">
            {data.map(product => (
              <MenuItem
                key={product.key}
                as="a"
                href={product.niceUrl}
                pr={10}
                onClick={e => {
                  e.preventDefault();
                  clickProduct(product, onOpen);
                }}
                icon={<img alt="" width={20} src={productIcons[product.key]} />}
              >
                {product.name}
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuDivider />
        </motion.div>
      )}
    </SimpleAlert>
  );
};
