import classNames from 'classnames';
import { motion } from 'motion/react';
import { PropsWithChildren } from 'react';
import styles from 'views/task/AnimatedPage.module.css';

interface AnimatedPageProps extends PropsWithChildren {
  reverse?: boolean;
  className?: string;
}

export const AnimatedPage = ({ children, reverse, className }: AnimatedPageProps) => (
  <motion.div
    className={classNames(styles.AnimatedPage, className)}
    custom={reverse}
    variants={{
      enter: (reverse: boolean) => ({
        opacity: 0,
        x: reverse ? '-200px' : '200px',
      }),
      exit: (reverse: boolean) => ({
        opacity: 0,
        x: reverse ? '200px' : '-200px',
      }),
    }}
    initial="enter"
    exit="exit"
    animate={{ opacity: 1, x: 0 }}
    transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
  >
    {children}
  </motion.div>
);
