import { Box, Flex, IconButton, Text, Tooltip, VStack } from '@chakra-ui/react';
import {
  faHouse,
  faLock,
  faSquarePollHorizontal,
  faUnlock,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LessonActivity,
  LessonActivityLockState,
  StudentState,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { LessonConferenceState } from '@sparx/api/apis/sparx/science/lessons/v1/teamteaching';
import { useUpdateLessonActivityState, useUpdateLessonState } from 'api/lessons';
import { SimpleAlert } from 'components/simplealert/SimpleAlert';
import { AnimatePresence, motion } from 'motion/react';
import React, { PropsWithChildren } from 'react';
import { CreateActivityModal } from 'views/lessons/createactivity/CreateActivityModal';
import { useLessonViewContext } from 'views/lessons/LessonView';
import { isStudentActive } from 'views/lessons/StudentActivity';

interface ManageActivitiesDrawerProps {
  onSelectActivity: (id: string) => void;
}

export const ManageActivitiesDrawer = ({ onSelectActivity }: ManageActivitiesDrawerProps) => {
  const { selectedActivities, readonly, lessonID, lessonState, studentStates } =
    useLessonViewContext();

  const getActivityList = (activities: LessonActivity[]) =>
    activities.map(activity => (
      <Activity
        lessonID={lessonID}
        activity={activity}
        students={studentStates}
        selected={selectedActivities.has(activity.lessonActivityId)}
        currentSet={lessonState.setLessonActivityId === activity.lessonActivityId}
        currentSetEphemeral={lessonState.setEphemeralLessonActivityId === activity.lessonActivityId}
        onClick={() => onSelectActivity(activity.lessonActivityId)}
        key={activity.lessonActivityId}
      />
    ));

  const standardActivities = lessonState.activities.filter(a => !a.ephemeral);
  const ephemeralActivities = lessonState.activities.filter(a => a.ephemeral);

  return (
    <VStack spacing={3} py={3} alignItems="stretch">
      <Text display="flex" fontWeight="bold" px={3} fontSize="sm">
        Activities
      </Text>
      <Box>
        <AnimatePresence initial={false}>
          {getActivityList(standardActivities)}
          {ephemeralActivities.length > 0 && (
            <Text fontWeight="bold" py={3} px={3} fontSize="sm">
              Survey questions
            </Text>
          )}
          {getActivityList(ephemeralActivities)}
        </AnimatePresence>
      </Box>
      <Flex alignItems="strech" flexDirection="column" px={4}>
        {!readonly && <CreateActivityModal />}
      </Flex>
    </VStack>
  );
};

const Activity = ({
  lessonID,
  activity,
  students,
  selected,
  currentSet,
  currentSetEphemeral,
  onClick,
}: {
  lessonID: string;
  activity: LessonActivity;
  students: StudentState[];
  selected?: boolean;
  currentSet?: boolean;
  currentSetEphemeral?: boolean;
  onClick: () => void;
}) => {
  const { lessonState, readonly, selectedStudents, onSelectActivity } = useLessonViewContext();
  const studentCount = students.filter(
    s => isStudentActive(s) && s.position?.lessonActivityId === activity.lessonActivityId,
  ).length;

  const isLocked = activity.lockState === LessonActivityLockState.LOCK_STATE_LOCKED;

  const { mutate: update, isLoading: isUpdating } = useUpdateLessonActivityState(lessonID);
  const { mutate: updateLesson, isLoading: isUpdatingLesson } = useUpdateLessonState(lessonID);

  const changeLockState = (locked: boolean) => {
    if (!isUpdating) {
      update({
        lessonActivityId: activity.lessonActivityId,
        lockState: locked
          ? LessonActivityLockState.LOCK_STATE_LOCKED
          : LessonActivityLockState.LOCK_STATE_UNLOCKED,
      });
    }
  };

  const setToTask = () => {
    if (isUpdatingLesson) return; // ignore

    if (selectedStudents.size > 0) {
      // Set just the selected students
      const studentSetLessonActivityIds = { ...(lessonState.studentSetLessonActivityIds || {}) };
      for (const student of selectedStudents) {
        // TODO(dothack) - fix hack - the activityid will contain a trialing . or .. which will
        //  denote the difference between the lesson-wide set activity and will alternate when
        //  the student is reset to the same activity.
        if (studentSetLessonActivityIds[student] === activity.lessonActivityId + '.') {
          studentSetLessonActivityIds[student] += '.';
        } else {
          studentSetLessonActivityIds[student] = activity.lessonActivityId + '.';
        }
      }
      updateLesson({ studentSetLessonActivityIds });
    } else {
      // Set the default for the lesson
      if (isLocked) changeLockState(false);
      onSelectActivity(activity.lessonActivityId, true);

      // Ensure that the whiteboards are hidden when setting to task
      let conferenceState: LessonConferenceState | undefined;
      if (lessonState.conferenceState?.whiteboardState?.whiteboardActivityId) {
        conferenceState = {
          ...lessonState.conferenceState,
          whiteboardState: {
            ...lessonState.conferenceState.whiteboardState,
            whiteboardActivityId: '', // Clear whiteboard activity
          },
        };
      }

      updateLesson(
        activity.ephemeral
          ? {
              setEphemeralLessonActivityId: isSet ? '' : activity.lessonActivityId,
              conferenceState,
            }
          : { setLessonActivityId: isSet ? '' : activity.lessonActivityId, conferenceState },
      );
    }
  };

  const isSet = currentSet || currentSetEphemeral;
  const haveSelectedStudents = selectedStudents.size > 0;
  const showAsSet = isSet && !haveSelectedStudents;
  const activityTypeName = activity.ephemeral ? 'survey' : 'task';

  const controls = (
    <Box px={2} onClick={e => e.stopPropagation()}>
      <Tooltip
        label={
          showAsSet
            ? `Unset as default ${activityTypeName}`
            : haveSelectedStudents
              ? `Push ${selectedStudents.size} student${
                  selectedStudents.size === 1 ? '' : 's'
                } to ${activityTypeName}`
              : `Push to ${activityTypeName}`
        }
        hasArrow={true}
        fontSize="sm"
        placement="left"
        px={3}
        py={2}
      >
        <IconButton
          aria-label="Task"
          size="sm"
          mr={1}
          isLoading={isUpdatingLesson}
          isDisabled={isLocked && haveSelectedStudents}
          onClick={setToTask}
          colorScheme={haveSelectedStudents ? 'buttonTeal' : showAsSet ? 'blue' : 'gray'}
        >
          <FontAwesomeIcon icon={activity.ephemeral ? faSquarePollHorizontal : faHouse} />
        </IconButton>
      </Tooltip>
      {!activity.ephemeral && (
        <SimpleAlert
          header="Lock task?"
          onConfirm={async () => changeLockState(!isLocked)}
          skip={isLocked || studentCount === 0}
          body={
            <>
              {studentCount} student{studentCount === 1 ? '' : 's'} will be kicked out of this task.
            </>
          }
        >
          {onClick => (
            <Tooltip
              label={!isLocked ? 'Lock task' : 'Unlock task'}
              hasArrow={true}
              fontSize="sm"
              placement="left"
              px={3}
              py={2}
            >
              <IconButton
                aria-label={!isLocked ? 'Lock task' : 'Unlock task'}
                size="sm"
                onClick={onClick}
                colorScheme={isLocked ? 'red' : 'gray'}
                isLoading={isUpdating}
                pointerEvents={haveSelectedStudents ? 'none' : undefined}
                opacity={haveSelectedStudents ? 0.2 : 1}
                transition="opacity 0.2s"
              >
                <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />
              </IconButton>
            </Tooltip>
          )}
        </SimpleAlert>
      )}
    </Box>
  );

  return (
    <Box
      as={motion.div}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      bgGradient={
        isLocked
          ? 'linear(to-l, red.50, transparent)'
          : selected
            ? 'linear(to-r, blue.50, transparent)'
            : undefined
      }
      _hover={{ boxShadow: '0 0 0 100px rgba(0, 0, 255, 0.01) inset', cursor: 'pointer' }}
      onClick={onClick}
      color={'gray.900'}
      display="flex"
      alignItems="center"
      overflow="hidden"
      borderBottomWidth={1}
      borderTopWidth={1}
      marginBottom="-1px"
      borderBottomColor="gray.100"
    >
      <Box
        w={selected ? 2 : 2}
        alignSelf="stretch"
        bg={!selected ? 'gray.100' : 'blue.400'}
        transition="background 0.1s ease-in-out"
      />
      <Box px={3} py={2} flex={1} display="flex" flexDirection="column">
        <Text lineHeight="1.2em" mb={1} as="span" fontSize="sm">
          {activity.displayName}
        </Text>
        <Text fontSize="xs" as="span" color={studentCount === 0 ? 'gray.500' : 'gray.700'}>
          {isLocked ? (
            <Text color="red.300">
              <Text display="inline-block" mr={2}>
                <FontAwesomeIcon icon={faLock} />
              </Text>
              Locked
            </Text>
          ) : (
            <>
              <Text display="inline-block" mr={2}>
                <FontAwesomeIcon icon={faUser} />
              </Text>
              {studentCount}
            </>
          )}
          {currentSet && <DefaultTaskLabel selected={selected}>Default task</DefaultTaskLabel>}
          {currentSetEphemeral && (
            <DefaultTaskLabel selected={selected}>Current survey</DefaultTaskLabel>
          )}
        </Text>
      </Box>
      {!readonly && controls}
    </Box>
  );
};

const DefaultTaskLabel = ({ children, selected }: PropsWithChildren<{ selected?: boolean }>) => (
  <Text
    display="inline-block"
    ml={4}
    my={-1}
    px={2}
    py={0.5}
    borderRadius="md"
    bg={selected ? 'white' : 'blue.50'}
    color="blue.700"
  >
    {children}
  </Text>
);
