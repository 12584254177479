import classNames from 'classnames';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  FloatingEntryExit,
  HorizontalSwipeEntryExit,
  VerticalSwipeEntryExit,
} from '../components/Animations';
import { AnswerContent } from '../components/AnswerContent';
import { CorrectIcon } from '../components/CorrectIcon';
import { NumericKeypad } from '../components/NumericKeypad';
import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IElement, IGroupElement, INumberFieldElement, ISlotElement } from '../question/types';
import { debounceCallback } from '../utils/debounce';
import { InlineSlotOptions } from './InlineSlotOptions';

enum questionPartResult {
  NULL,
  CORRECT,
  INCORRECT,
}

const getKeypadSizes = (contentElement: HTMLDivElement | null) => {
  if (!contentElement) {
    return { keypadWidth: 0, keypadWidthWithPadding: 0, keypadHeight: 0 };
  }
  // keypad is always 13.2em wide (14.2em including padding), and 7.2em + 2px tall
  const fontSize = parseFloat(window.getComputedStyle(contentElement).fontSize.replace('px', ''));
  return {
    keypadWidth: fontSize * 13.2,
    keypadWidthWithPadding: fontSize * 14.2,
    keypadHeight: fontSize * 7.2 + 2,
  };
};

// todo:
// - this hook just checks if content and label fit in the question, so wont work as expected for left image questions
// - this hook only cares about keypad size, and should probably care about slot options size
const getIsHorizontalLayout = (
  groupElement: IGroupElement,
  contentRef: React.RefObject<HTMLDivElement>,
  labelRef: React.RefObject<HTMLDivElement>,
  questionElement: HTMLElement | null,
) => {
  if (
    groupElement.style !== 'fraction' &&
    groupElement.style !== 'vector' &&
    groupElement.style !== 'matrix-static'
  ) {
    return false;
  }
  if (!contentRef.current || !questionElement) {
    return false;
  }

  // use horizontal layout if the keypad, label and content can fit side by side
  const contentRect = contentRef.current.getBoundingClientRect();
  const labelRect = labelRef.current?.getBoundingClientRect();
  const questionRect = questionElement.getBoundingClientRect();
  const { keypadWidthWithPadding } = getKeypadSizes(contentRef.current);
  return contentRect.width + (labelRect?.width || 0) + keypadWidthWithPadding < questionRect.width;
};

interface AnswerPartProps {
  answerPartIndex?: number;
  groupElement: IGroupElement;
}

export const AnswerPart = ({
  groupElement,
  children,
  answerPartIndex,
}: PropsWithChildren<AnswerPartProps>) => {
  const {
    openElementRef,
    setOpenElementRef,
    questionElement,
    isSingleNumericInput,
    readOnly,
    mode,
    gapEvaluations,
    questionMarkingMode,
    firstChanceGapEvaluationsRef,
    inlineSlotsAndNumberFields,
  } = useSparxQuestionContext();
  const answerPartRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const [keypadParent, setKeypadParent] = useState<HTMLElement | null>(null);
  const [verticalSlotOptions, setVerticalSlotOptions] = useState<HTMLElement | null>(null);
  const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
  const [animCompleteCount, setAnimCompleteCount] = useState(0);
  // Currently this is only set to 'part' for maths, otherwise it is undefined
  const showPartMarking = questionMarkingMode === 'part';

  // Determine if to display this content with inline text, numeric, and slot input styles.
  // This styling will make text inline so it can overflow with the inputs.
  const showFloatingInputs = useMemo(
    () =>
      !isSingleNumericInput &&
      isInlineInput(
        groupElement,
        inlineSlotsAndNumberFields ? ['text-field', 'slot', 'number-field'] : ['text-field'],
      ),
    [groupElement, inlineSlotsAndNumberFields, isSingleNumericInput],
  );

  const partResult = useMemo((): questionPartResult => {
    if (!showPartMarking) {
      return questionPartResult.NULL;
    }
    // Get the input element(s) for this answer part, add typeguard to tell typescript that
    // the returned elements will have a ref property
    const answerPartGaps = groupElement.content.filter(
      (element: IElement): element is IElement & { ref: string } => 'ref' in element,
    );

    if (answerPartGaps.length === 0) {
      return questionPartResult.NULL;
    }

    // If we have current gap evaluations (i.e. directly after answering the question)
    // use these to show marking on the answer parts:
    if (gapEvaluations) {
      // If any of the gaps in this part are incorrect, mark the part as incorrect.
      for (let i = 0; i < answerPartGaps.length; i++) {
        const gap = answerPartGaps[i];
        if (!gapEvaluations[gap.ref]?.correct) {
          return questionPartResult.INCORRECT;
        }
      }

      // Otherwise, all gaps in the part are correct, so mark the part as correct
      return questionPartResult.CORRECT;
    }

    // If we have firstChanceGapEvaluationsRef but no current gapEvaluations
    // use these to show marking on previously-correct parts in second chance.
    // Leave previously-incorrect parts unmarked:
    if (firstChanceGapEvaluationsRef?.current) {
      const prevGaps = firstChanceGapEvaluationsRef.current;
      for (let i = 0; i < answerPartGaps.length; i++) {
        const gap = answerPartGaps[i];
        if (!prevGaps[gap.ref]?.correct) {
          return questionPartResult.NULL;
        }
      }
      return questionPartResult.CORRECT;
    }

    // If we don't have any gap evaluations or previous gap evaluations, don't mark
    return questionPartResult.NULL;
  }, [gapEvaluations, groupElement.content, firstChanceGapEvaluationsRef, showPartMarking]);

  const { keypadWidth, keypadHeight } = getKeypadSizes(contentRef.current);

  // work out whether to layout the keypad/options to the right or below (as options are variable
  // width just do this using the known keypad width)
  useEffect(() => {
    const setHL = () =>
      setIsHorizontalLayout(
        getIsHorizontalLayout(groupElement, contentRef, labelRef, questionElement),
      );
    setHL();
    const aborter = new AbortController();
    window.addEventListener('resize', debounceCallback(setHL, 200), {
      signal: aborter.signal,
    });
    return () => aborter.abort();
  }, [groupElement, questionElement]);

  const shouldScrollIntoView = !readOnly && mode !== 'combined';

  // find open slot element (i.e. slot where the answer options are displayed) or selected numeric
  // input element
  const { numericInputElement, slotElement, elementToOpen } = useMemo(() => {
    let numericInputElement: INumberFieldElement | undefined;
    let slotElement: ISlotElement | undefined;
    let elementToOpen: string | undefined;
    if (groupElement) {
      for (const childContent of groupElement.content) {
        if (childContent.element === 'number-field') {
          if (childContent.ref === openElementRef) {
            numericInputElement = childContent;
          } else if (isSingleNumericInput) {
            elementToOpen = childContent.ref;
          }
        }
        if (childContent.element === 'slot' && childContent.ref === openElementRef) {
          slotElement = childContent;
        }
      }
    }
    return { numericInputElement, slotElement, elementToOpen };
  }, [groupElement, openElementRef, isSingleNumericInput]);
  useEffect(() => {
    if ((isSingleNumericInput || numericInputElement) && readOnly) {
      // If readOnly and a numeric input close the numpad
      // This is primarily to save space in science multistep continuous questions
      setOpenElementRef('');
    } else if (elementToOpen) {
      setOpenElementRef(elementToOpen);
    }
  }, [elementToOpen, setOpenElementRef, readOnly, isSingleNumericInput, numericInputElement]);

  const onAnimInComplete = useCallback(() => {
    // scroll answer part into view
    if ((numericInputElement || slotElement) && shouldScrollIntoView) {
      answerPartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    // update the animCompleteCount to trigger the NumericKeypad to reposition
    setAnimCompleteCount(c => c + 1);
  }, [numericInputElement, slotElement, shouldScrollIntoView]);

  // create keypads
  const horizontalKeypad = (
    <HorizontalSwipeEntryExit
      transitionKey="numeric-input"
      height={keypadHeight}
      width={keypadWidth}
      onAnimInComplete={onAnimInComplete}
    >
      {numericInputElement && isHorizontalLayout && (
        <NumericKeypad inputElement={numericInputElement} alwaysOpen={isSingleNumericInput} />
      )}
    </HorizontalSwipeEntryExit>
  );
  let verticalKeypad = numericInputElement && !isHorizontalLayout && (
    // wrapping this in a div styled with KeypadBelow allows the positioning code in
    // NumericKeypad to work
    <div className={styles.KeypadBelow} ref={setKeypadParent}>
      <NumericKeypad
        inputElement={numericInputElement}
        parentElement={keypadParent}
        alwaysOpen={isSingleNumericInput}
        shouldPosition
        repositionTrigger={animCompleteCount}
        floating={showFloatingInputs}
      />
    </div>
  );

  if (showFloatingInputs) {
    verticalKeypad = (
      <FloatingEntryExit classname={styles.KeypadFloating}>{verticalKeypad}</FloatingEntryExit>
    );
  } else {
    verticalKeypad = (
      <VerticalSwipeEntryExit
        transitionKey="numeric-input"
        height={keypadHeight}
        onAnimInComplete={onAnimInComplete}
      >
        {verticalKeypad}
      </VerticalSwipeEntryExit>
    );
  }

  // create slot options
  const horizontalOptions = (
    <HorizontalSwipeEntryExit transitionKey="slot" onAnimInComplete={onAnimInComplete}>
      {slotElement && isHorizontalLayout && (
        <InlineSlotOptions element={slotElement} answerPartIndex={answerPartIndex} />
      )}
    </HorizontalSwipeEntryExit>
  );

  const slotOptionsWrapperRef = useRef<HTMLDivElement | null>(null);
  let verticalOptions = slotElement && !isHorizontalLayout && (
    // wrapping this in a div styled with SlotsBelow allows the positioning code in
    // InlineSlotOptions to work
    <div className={styles.SlotsBelow} ref={slotOptionsWrapperRef}>
      <InlineSlotOptions
        element={slotElement}
        setVerticalSlotOptions={setVerticalSlotOptions}
        answerPartIndex={answerPartIndex}
        parent={slotOptionsWrapperRef.current}
        vertical
        floating={showFloatingInputs}
      />
    </div>
  );
  if (showFloatingInputs) {
    verticalOptions = (
      <FloatingEntryExit classname={styles.SlotsFloating}>{verticalOptions}</FloatingEntryExit>
    );
  } else {
    verticalOptions = (
      <VerticalSwipeEntryExit
        transitionKey="slot"
        height={verticalSlotOptions?.clientHeight || 0}
        onAnimInComplete={onAnimInComplete}
      >
        {verticalOptions}
      </VerticalSwipeEntryExit>
    );
  }

  const showPartResult = partResult !== questionPartResult.NULL;
  const isCorrect = partResult === questionPartResult.CORRECT;

  return (
    <div
      key={answerPartIndex}
      className={classNames({
        [styles.AnswerPart]: true,
        [styles.MarkByPart]: showPartResult,
        [styles.IsCorrect]: isCorrect,
        [styles.AnswerPartHorizontal]: isHorizontalLayout,
        [styles.AnswerPartVertical]: !isHorizontalLayout,
      })}
      ref={answerPartRef}
    >
      {showPartResult && <CorrectIcon correct={isCorrect} analyticsAnswerType="answer-part" />}
      <AnswerContent
        groupElement={groupElement}
        contentRef={contentRef}
        labelRef={labelRef}
        keypad={horizontalKeypad}
        inlineInputs={showFloatingInputs}
      >
        {children}
      </AnswerContent>
      {verticalKeypad}
      {horizontalOptions}
      {verticalOptions}
    </div>
  );
};

/**
 * We can use the InlineTextGroup style if the group element contains only text, numeric or slot fields and
 * text elements.
 */
const isInlineInput = (element: IGroupElement, acceptableElements: string[]) => {
  let hasTextField = false;
  // if we have a style (fraction, matrix or vector) we don't want to show anything inline
  if (element.style) {
    return false;
  }
  for (const el of element.content) {
    switch (true) {
      case acceptableElements.includes(el.element):
        hasTextField = true;
        break;
      case el.element === 'text':
        break; // Ignore
      default:
        return false; // Unsupported
    }
  }
  return hasTextField && element.content.length > 1;
};
