import classNames from 'classnames';
import { FC } from 'react';
import * as React from 'react';

import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';

export const TextFieldWarning: FC<{
  children?: React.ReactNode;
  visible?: boolean;
  leftAlign?: boolean;
  parent?: HTMLElement | null;
}> = ({ children, visible, parent, leftAlign }) => {
  const { questionElement } = useSparxQuestionContext();
  const [warningElement, setWarningElement] = React.useState<HTMLDivElement | null>(null);

  // decide if the warning should live above or below the question
  const [showBelow, setShowBelow] = React.useState(false);
  React.useEffect(() => {
    const questionContainer = questionElement?.offsetParent;
    if (!questionContainer || !warningElement || !parent) {
      return;
    }
    const questionContainerRect = questionContainer.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    const warningRect = warningElement?.getBoundingClientRect();
    if (parentRect.top - questionContainerRect.top - 20 < warningRect.height) {
      setShowBelow(true);
    } else {
      setShowBelow(false);
    }
  }, [parent, questionElement, visible, warningElement]);

  // decide the offset for the warning to keep it on screen
  const [offset, setOffset] = React.useState<number | undefined>(undefined);
  const setOffsetWithLeeway = (v: number | undefined) => {
    setOffset(c => {
      if (c == undefined || v == undefined) {
        return v;
      }
      return Math.abs(v - c) < 3 ? c : v;
    });
  };

  const recalculateOffset = () => {
    if (!warningElement || !questionElement || !parent) {
      setOffsetWithLeeway(undefined);
      return;
    }
    const baseOffset = leftAlign ? 0 : (parent.clientWidth - warningElement.clientWidth) / 2;
    const warningRect = warningElement.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    // if the warning is wider than the question, theres nothing we can really do, return the base offset
    if (warningRect.width > questionElement.clientWidth) {
      setOffsetWithLeeway(baseOffset);
      return;
    }

    // if the warning is going to be off the left side of the screen, move it right a bit
    const requiredLeftPadding = questionElement.getBoundingClientRect().left;
    if (parentRect.left + baseOffset < requiredLeftPadding) {
      const overshoot = requiredLeftPadding - (parentRect.left + baseOffset);
      setOffsetWithLeeway(baseOffset + overshoot);
      return;
    }

    // if the warning is going be off the right side of the screen, move it left a bit
    const requiredRightPadding = window.innerWidth - questionElement.getBoundingClientRect().right;
    if (
      parentRect.left + baseOffset + warningRect.width >
      window.innerWidth - requiredRightPadding
    ) {
      const overshoot =
        parentRect.left +
        baseOffset +
        warningRect.width -
        (window.innerWidth - requiredRightPadding);
      setOffsetWithLeeway(baseOffset - overshoot);
      return;
    }

    // otherwise, the warning is a ok, so leave it be
    setOffsetWithLeeway(baseOffset);
    return;
  };

  return (
    <div
      className={classNames(styles.TextFieldWarningTarget, {
        [styles.TextFieldWarningTargetVisible]: visible,
        [styles.Below]: showBelow,
      })}
      ref={setWarningElement}
      style={offset != undefined ? { left: offset } : undefined}
    >
      <div className={styles.TextFieldWarningWrapper} ref={recalculateOffset}>
        {children}
      </div>
    </div>
  );
};
